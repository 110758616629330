import LogEventsBeaconService from "./logEvents/logEvents";

const BeaconServices = {
  logEvents: { ...LogEventsBeaconService },
};

if (process.env.NX_PUBLIC_STAGE === "dev")
  window.tvdBeaconServices = BeaconServices;

export default BeaconServices;
