import { __awaiter } from "tslib";
import { LogEventEntitiesEnum, LogEventTypesEnum, } from "@baplie-viewer2/tedivo-api-models";
import { formatDateInCurrentTimezone, safeDateString, } from "@baplie-viewer2/tedivo-pure-helpers";
import mapDataToDictionary from "../../common/dictionaryDisplay/helpers/mapDataToDictionary";
import Services from "../../../app/services";
import SmartTable from "../../common/smartTable/smart-table.element";
import getTranslatedDictionary from "../../common/dictionaryDisplay/helpers/getTranslatedDictionary";
import { getTranslation } from "../../../app/i18/i18tn";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
export function showFileHistory(fileInfo) {
    if (fileInfo === undefined)
        return undefined;
    let minHeight = 0;
    let lastEvaluatedLogKey = undefined;
    let totalLogData = [];
    let usersNamesByIdOfOrganization = undefined;
    const { holder, historyListTable, versionsListTable, showHistoryButton, showVersionsButton, plusIcon, displayMetaHistoryDiv, tabGroup, } = createBaseNodes();
    holder.setLoading(true);
    securityModule
        .getOrganizationUsersBySub(fileInfo.organizationId)
        .then((usersById) => {
        usersNamesByIdOfOrganization = usersById;
        displayMetaHistoryDiv.elements = createBasicHistoryPart(fileInfo);
        holder.setLoading(false);
    });
    const smartTableLogs = createEventsSmartTable(historyListTable, fileInfo.fromBvoName);
    const smartTableFVersions = createFileVersionsSmartTable(versionsListTable);
    showHistoryButton.addEventListener("click", () => {
        loadEventLogs(fileInfo, smartTableLogs);
    });
    loadFileVersions(fileInfo, smartTableFVersions);
    let shownFirstHistory = false;
    tabGroup.addEventListener("sl-tab-show", (event) => {
        if (shownFirstHistory)
            return;
        const evDet = event;
        if (evDet.detail.name === "history-on-demand") {
            loadEventLogs(fileInfo, smartTableLogs);
            shownFirstHistory = true;
        }
    });
    return holder;
    function createBaseNodes() {
        const holder = document.createElement("div-spinner-element");
        const displayMetaHistoryDiv = document.createElement("dictionary-display-element");
        displayMetaHistoryDiv.className = "fileHistory";
        holder.appendChild(displayMetaHistoryDiv);
        const tabGroup = document.createElement("sl-tab-group");
        const tabHistoryMenu = document.createElement("sl-tab");
        const tabVersionsMenu = document.createElement("sl-tab");
        const tabHistoryPanel = document.createElement("sl-tab-panel");
        const tabVersionsPanel = document.createElement("sl-tab-panel");
        tabHistoryMenu.innerHTML = getTranslation("view:edit.fileHistory.tabHistory");
        tabVersionsMenu.innerHTML = getTranslation("view:edit.fileHistory.tabVersions");
        tabHistoryMenu.slot = "nav";
        tabHistoryMenu.panel = "history-on-demand";
        tabVersionsMenu.slot = "nav";
        tabVersionsMenu.panel = "versions-on-demand";
        tabVersionsMenu.active = true;
        tabHistoryPanel.name = "history-on-demand";
        tabHistoryPanel.className = "history-on-demand";
        tabVersionsPanel.name = "versions-on-demand";
        tabVersionsPanel.className = "versions-on-demand";
        tabGroup.appendChild(tabVersionsMenu);
        tabGroup.appendChild(tabHistoryMenu);
        tabGroup.appendChild(tabVersionsPanel);
        tabGroup.appendChild(tabHistoryPanel);
        holder.appendChild(tabGroup);
        // File History
        const showHistoryButton = document.createElement("sl-button");
        showHistoryButton.variant = "primary";
        showHistoryButton.size = "small";
        showHistoryButton.innerHTML = getTranslation("view:edit.fileHistory.loadEvents");
        const plusIcon = document.createElement("sl-icon");
        plusIcon.name = "plus-lg";
        plusIcon.slot = "prefix";
        showHistoryButton.appendChild(plusIcon);
        const historyListTable = document.createElement("div");
        tabHistoryPanel.appendChild(historyListTable);
        tabHistoryPanel.appendChild(showHistoryButton);
        // Versions
        const showVersionsButton = document.createElement("sl-button");
        showVersionsButton.variant = "primary";
        showVersionsButton.size = "small";
        showVersionsButton.innerHTML = getTranslation("view:edit.fileHistory.loadVersions");
        const plusIcon2 = document.createElement("sl-icon");
        plusIcon2.name = "plus-lg";
        plusIcon2.slot = "prefix";
        showVersionsButton.appendChild(plusIcon2);
        const versionsListTable = document.createElement("div");
        tabVersionsPanel.appendChild(versionsListTable);
        tabVersionsPanel.appendChild(showVersionsButton);
        return {
            holder,
            historyListTable,
            versionsListTable,
            showHistoryButton,
            showVersionsButton,
            plusIcon,
            displayMetaHistoryDiv,
            tabGroup,
            tabHistoryPanel,
            tabVersionsPanel,
        };
    }
    function createBasicHistoryPart(tvdId) {
        const partData = {
            createdAt: tvdId.createdAt,
            createdBy: tvdId.userSub,
            updatedAt: tvdId.updatedAt,
            updatedBy: tvdId.updatedBy,
            fromBvoName: tvdId.fromBvoName,
            lastComment: tvdId.lastComment,
        };
        const isWideEnough = document.documentElement.clientWidth > 1400;
        const HistoryDataConfig = [
            {
                label: "view:edit.fileHistory.createdAt",
                key: "createdAt",
                type: "longDate",
                undefinedIsDash: true,
                format: (v) => formatDateInCurrentTimezone(v),
            },
            {
                label: "view:edit.fileHistory.createdBy",
                key: "createdBy",
                type: "string",
                undefinedIsDash: true,
                format: (v) => partData.fromBvoName
                    ? getTranslation("general:bvo")
                    : (usersNamesByIdOfOrganization === null || usersNamesByIdOfOrganization === void 0 ? void 0 : usersNamesByIdOfOrganization[v]) || "-",
            },
            {
                label: "view:edit.fileHistory.updatedAt",
                key: "updatedAt",
                type: "longDate",
                undefinedIsDash: true,
                format: (v) => formatDateInCurrentTimezone(v),
            },
            {
                label: "view:edit.fileHistory.updatedBy",
                key: "updatedBy",
                type: "string",
                undefinedIsDash: true,
                format: (v) => (usersNamesByIdOfOrganization === null || usersNamesByIdOfOrganization === void 0 ? void 0 : usersNamesByIdOfOrganization[v]) || "-",
            },
            {
                breakBefore: !isWideEnough,
                label: "view:edit.fileHistory.lastComment",
                key: "lastComment",
                type: "string",
                undefinedIsDash: true,
                format: (v) => v.replace(/\n/g, "<br />"),
            },
        ];
        const shipPartDataElements = getTranslatedDictionary(mapDataToDictionary(partData, HistoryDataConfig));
        return shipPartDataElements;
    }
    function createEventsSmartTable(node, fromBvoName) {
        const fields = [
            {
                name: "date",
                label: getTranslation("view:edit.fileHistory.fields.date"),
                valueFunction: (dta) => formatDateInCurrentTimezone(dta.date),
                className: "centered",
                fixedWidth: "180px",
            },
            {
                name: "subEvent",
                label: getTranslation("view:edit.fileHistory.fields.event"),
                valueFunction: (dta) => {
                    var _a;
                    switch (dta.eventType) {
                        case LogEventTypesEnum.Modified:
                            if (dta.subEvent) {
                                const parts = dta.subEvent.split("-");
                                const part0 = parts === null || parts === void 0 ? void 0 : parts[0];
                                if (part0 === "Bay") {
                                    return `<span class="span-action">${getTranslation(`general:common.modified`)}:</span> ${getTranslation(`view:edit.fileHistory.eventSubTypes.Bay`)}
                     ${parts === null || parts === void 0 ? void 0 : parts[1]} ${(parts === null || parts === void 0 ? void 0 : parts[2]) === "Ab"
                                        ? getTranslation("enums:BayLevelEnum.ABOVE")
                                        : getTranslation("enums:BayLevelEnum.BELOW")}`;
                                }
                                else {
                                    return `<span class="span-action">${getTranslation(`general:common.modified`)}:</span> ${getTranslation(`view:edit.fileHistory.eventSubTypes.${part0}`)}`;
                                }
                            }
                            else {
                                return "";
                            }
                        case LogEventTypesEnum.StateChanged:
                            return `<span class="span-action">${getTranslation("view:edit.fileHistory.eventSubTypes.StateChanged")}:</span> ${getTranslation(`enums:FileStateEnum.${dta.details.oldState}`)} &rarr; ${getTranslation(`enums:FileStateEnum.${dta.details.newState}`)} ${dta.details.comments
                                ? ` <br /><span class="history-comments">${dta.details.comments.replace(/\n/g, "<br />")}</span>`
                                : ""}`;
                            break;
                        case LogEventTypesEnum.Created:
                            if (dta.eventEntity === LogEventEntitiesEnum.File) {
                                return `<span class="span-action">${getTranslation("view:edit.fileHistory.eventTypes.Created")}:</span> ${dta.details.fileName} &rarr; ${dta.details.source
                                    ? getTranslation(`enums:TSourceFileCreated.${dta.details.source}`)
                                    : ""}`;
                            }
                            else {
                                return "";
                            }
                        case LogEventTypesEnum.ClonedByThirdParty:
                            return `<span class="span-action">${getTranslation("view:edit.fileHistory.eventTypes.ClonedByThirdParty")}:</span> ${dta.details.orgName}`;
                            break;
                        case LogEventTypesEnum.ReplacedByVersion:
                            return `<span class="span-action">${getTranslation(`general:common.modified`)}:</span> ${getTranslation(`view:edit.fileHistory.eventSubTypes.VersionReplace`)}: v${((_a = dta.details) === null || _a === void 0 ? void 0 : _a.version) || ""}`;
                        case LogEventTypesEnum.ReplacedByConsumer:
                            return `<span class="span-action">${getTranslation(`general:common.modified`)}:</span> ${getTranslation(`view:edit.fileHistory.eventSubTypes.VersionReplace`)}`;
                            break;
                        case LogEventTypesEnum.UpVoted:
                        case LogEventTypesEnum.RemovedVote:
                            return `<span class="span-action">${getTranslation(`view:edit.fileHistory.eventTypes.${dta.eventType}`)}:</span> ${dta.details.orgName}`;
                            break;
                        default:
                            return "";
                    }
                },
            },
            {
                name: "userSub",
                label: getTranslation("general:common.author"),
                valueFunction: (dta) => fromBvoName &&
                    dta.eventType === LogEventTypesEnum.Created &&
                    dta.eventEntity === LogEventEntitiesEnum.File
                    ? getTranslation("general:bvo")
                    : (usersNamesByIdOfOrganization === null || usersNamesByIdOfOrganization === void 0 ? void 0 : usersNamesByIdOfOrganization[dta.userSub]) ||
                        dta.userDisplayName ||
                        "-",
            },
        ];
        const smartTableLogs = new SmartTable().initialize({
            className: "tvd-table tvd-table-smart-table margin-bottom",
            data: [],
            fields,
            initialRows: 1000,
            settings: {
                sortEnabled: false,
                sortVisible: false,
                filterEnabled: false,
            },
            pkFunction: (dta) => `${new Date(dta.date).toISOString()}#${dta.itemId}`,
        });
        node.appendChild(smartTableLogs);
        node.style.display = "none";
        return smartTableLogs;
    }
    function createFileVersionsSmartTable(node) {
        const fields = [
            {
                name: "vNumber",
                label: getTranslation("general:common.version"),
                valueFunction: (dta) => `v${dta.vNumber}`,
                className: "centered",
                fixedWidth: "60px",
            },
            {
                name: "date",
                label: getTranslation("view:edit.fileHistory.fields.date"),
                valueFunction: (dta) => formatDateInCurrentTimezone(dta.date),
                className: "centered",
                fixedWidth: "180px",
            },
            {
                name: "reason",
                label: getTranslation("view:edit.fileHistory.fields.event"),
                valueFunction: (dta) => getTranslation(`enums:VersionReasonEnum.${dta.reason || "PUBLISHED"}`),
                className: "centered",
            },
            {
                name: "fileId",
                label: getTranslation("view:edit.fileHistory.fields.comments"),
                valueFunction: (dta) => dta.reason === "REPLACED_BY_VERSION"
                    ? getTranslation("view:comparer.replacedWithVersion", {
                        version: dta.details.comments,
                    })
                    : dta.details.comments || "-",
            },
            {
                name: "fileId",
                label: getTranslation("general:common.view"),
                type: "icon",
                icon: "eye",
                notSortable: true,
                fixedWidth: "40px",
                smallPadding: true,
                onClick: (dta) => {
                    router.navigate(routeFns.ovdViewOnlyVersion(dta.fileId, encodeURI(safeDateString(dta.date))), {
                        cloudId: dta.fileId,
                        organizationId: dta.organizationId,
                        source: "cloudOvdJson",
                    });
                },
            },
            {
                name: "fileId",
                label: getTranslation("general:common.compare"),
                type: "icon",
                icon: "code-slash",
                notSortable: true,
                fixedWidth: "80px",
                smallPadding: true,
                onClick: (dta) => {
                    const route = routeFns.fileCompareOwnVersion(dta.fileId, encodeURI(safeDateString(dta.date)));
                    router.navigate(route, { organizationId: dta.organizationId });
                },
            },
        ];
        const smartTableFVersions = new SmartTable().initialize({
            className: "tvd-table tvd-table-smart-table margin-bottom",
            data: [],
            fields,
            initialRows: 1000,
            settings: {
                sortEnabled: false,
                sortVisible: false,
                filterEnabled: false,
            },
            pkFunction: (dta) => `${new Date(dta.date).toISOString()}`,
        });
        node.appendChild(smartTableFVersions);
        node.style.display = "none";
        return smartTableFVersions;
    }
    function loadEventLogs(tvdId, smartTableLogs) {
        return __awaiter(this, void 0, void 0, function* () {
            showHistoryButton.loading = true;
            showHistoryButton.disabled = true;
            const { statusCode, data } = yield Services.logEvents.getFileLogEvents(tvdId.organizationId, tvdId.fileId, lastEvaluatedLogKey);
            if (statusCode !== 200) {
                showHistoryButton.loading = false;
                if (statusCode !== 404)
                    showHistoryButton.variant = "danger";
                showHistoryButton.innerHTML = getTranslation(statusCode === 404
                    ? "view:edit.fileHistory.loadEventsNoEvents"
                    : "view:edit.fileHistory.loadEventsError");
                return;
            }
            historyListTable.style.display = "block";
            lastEvaluatedLogKey = data === null || data === void 0 ? void 0 : data.lastEvaluatedKey;
            totalLogData = totalLogData.concat((data === null || data === void 0 ? void 0 : data.data) || []);
            if (totalLogData.length !== 0) {
                smartTableLogs.updateData(totalLogData);
                updateTabHeight();
            }
            if (!lastEvaluatedLogKey) {
                showHistoryButton.style.display = "none";
            }
            else {
                showHistoryButton.loading = false;
                showHistoryButton.disabled = false;
                showHistoryButton.innerHTML = getTranslation("view:edit.fileHistory.loadMoreEvents");
                showHistoryButton.appendChild(plusIcon);
            }
        });
    }
    function loadFileVersions(tvdId, smartTableFVersions) {
        return __awaiter(this, void 0, void 0, function* () {
            showVersionsButton.loading = true;
            showVersionsButton.disabled = true;
            const { statusCode, data } = yield Services.files.getFileVersions(tvdId.organizationId, tvdId.fileId);
            showVersionsButton.loading = false;
            if (statusCode !== 200) {
                if (statusCode !== 404)
                    showVersionsButton.variant = "danger";
                showVersionsButton.innerHTML = getTranslation(statusCode === 404
                    ? "view:edit.fileHistory.loadEventsNoEvents"
                    : "view:edit.fileHistory.loadEventsError");
                showVersionsButton.disabled = true;
                return;
            }
            versionsListTable.style.display = "block";
            const sortedData = data === null || data === void 0 ? void 0 : data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
            if (sortedData && sortedData.length > 0) {
                smartTableFVersions.updateData(sortedData);
                updateTabHeight();
            }
            showVersionsButton.style.display = "none";
        });
    }
    function updateTabHeight() {
        const tHeight = tabGroup.offsetHeight;
        if (tHeight > minHeight) {
            minHeight = tHeight;
            tabGroup.style.minHeight = `${minHeight - 15}px`;
        }
    }
}
