/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { createErrorNotification } from "../notifications/createNotification";
import { getTranslation } from "../i18/i18tn";
import securityModule from "../security/SecurityModule";

class HttpClient {
  private baseUrl: string;
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.baseUrl = (
      process.env.NX_PUBLIC_API_URL || "http://127.0.0.1:3000"
    ).replace(/$^\//, "");

    this.axiosInstance = axios.create();
  }

  public async request<TResponse>({
    method,
    url,
    data,
  }: IRequestProps): Promise<IResponseModel<TResponse>> {
    try {
      const config: AxiosRequestConfig = {};

      if (securityModule.idToken) {
        config.headers = {
          Authorization: `Bearer ${securityModule.idToken}`,
          "X-Tvd-Org-Id": securityModule.currentOrganizationId,
        };
      }

      securityModule.xhrRequestFired();

      const callUrl = `${this.baseUrl}/${url
        .replace(/\/{2,4}/g, "/")
        .replace(/^\//, "")}`;
      let response: AxiosResponse<TResponse> | undefined;
      let params: unknown | undefined = undefined;

      switch (method) {
        case "GET": {
          if (data) params = { id: data };
          response = await this.axiosInstance.get(callUrl, {
            ...config,
            params,
          });
          break;
        }
        case "POST": {
          response = await this.axiosInstance.post(callUrl, data, config);
          break;
        }
        case "PUT": {
          response = await this.axiosInstance.put(callUrl, data, config);
          break;
        }
        case "DELETE": {
          response = await this.axiosInstance.delete(callUrl, {
            ...config,
            params: data,
          });
          break;
        }
        default:
          break;
      }

      const statusCode = response?.status || 400;
      const responseModel: IResponseModel<TResponse> = {
        data: response?.data,
        statusCode,
      };

      return responseModel;
    } catch (error: any) {
      console.log(error);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if ((error as any).response?.status === 401) {
          securityModule.signOut();
        }

        const errRes = error.response.data;
        createErrorNotification(
          error === undefined
            ? "errors:errorHasOcurred"
            : typeof errRes === "string"
            ? errRes
            : getTranslation(
                (errRes as any).translationKey ||
                  (errRes as any).message ||
                  error.response.message ||
                  "errors:errorHasOcurred",
              ),
        );

        return {
          statusCode: (error as any).response?.status || 400,
          code: (error as any).response?.data?.code,
          message: (error as any).response?.data?.message,
        };
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        securityModule.dispatchEvent(new CustomEvent("ajaxError"));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }

      return {
        statusCode: 400,
        code: "",
        message: "Unknown error",
        data: undefined as never,
      };
    }
  }
}

export default HttpClient;

interface IRequestProps<T = unknown> {
  method: "GET" | "POST" | "DELETE" | "PUT";
  url: string;
  data?: TextDecodeOptions | FormData | T;
}

export type IResponseModel<T> =
  | {
      statusCode: number;
      data?: T;
      code?: never;
      message?: never;
    }
  | {
      statusCode: number;
      data: never;
      code?: string;
      message?: string;
    };

export type IResponseModelWithPagination<T> =
  | {
      statusCode: number;
      data?: TDataWithPagination<T>;
      code?: never;
      message?: never;
    }
  | {
      statusCode: number;
      data: never;
      code?: string;
      message?: string;
    };

export type TDataWithPagination<T> = {
  lastEvaluatedKey: string;
  data: T;
};
