import { IApiKeyRecord, systemRights } from "@baplie-viewer2/tedivo-api-models";
import {
  IFields,
  IFormReturn,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";

import { ITable } from "../../common/smartTable/ITable";
import { ListComponentWithActions } from "../../common/ListComponentWithActions";
import Services from "../../../app/services";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import { getTranslation } from "../../../app/i18/i18tn";
import goSquared from "../../../app/tracking/goSquared";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import { z } from "zod";

export class TVDApiKeysComponent extends ListComponentWithActions<IApiKeyRecord> {
  public static observedAttributes = [];

  constructor() {
    super();

    this.h1Label = "general:apiKeys.title";
    this.goSquaredLabel = "Admin/API-Keys";
    this.topLabel = "menu:organization";
    this.createLabel = !securityModule.planIsReadOnly
      ? "general:apiKeys.adminCreate"
      : "";
    this.deleteLabel = "general:apiKeys.delete";

    this.serviceListCall = Services.apiKeys.getAll;
    this.serviceDeleteCall = Services.apiKeys.delete;
    this.serviceEditCall = Services.apiKeys.update;

    this.systemRightToEdit = systemRights.USER.UserCanCreateApiKeys;

    this.tableConfig = (data: IApiKeyRecord[]) =>
      createApiKeysTableConfig(
        data,
        this.systemRightToEdit,
        this.openEditModal,
        this.openDeleteModal,
        securityModule.planIsReadOnly,
      );

    this.createEditForm = createApiKeyEdit;

    setAppTitle(getTranslation("general:apiKeys.title"));

    goSquared.trackPage("Api-Keys");
  }
}

customElements.define("tvd-apikeys-component", TVDApiKeysComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-apikeys-component": TVDApiKeysComponent;
  }
}

function createApiKeysTableConfig(
  apiKeys: IApiKeyRecord[],
  systemRightToEdit: string | undefined,
  onEditSaveFn: (pk: string, data: IApiKeyRecord) => void,
  onDeleteFn: (
    pk: string,
    data: IApiKeyRecord,
    name: keyof IApiKeyRecord,
  ) => void,
  isReadOnly: boolean,
): ITable<IApiKeyRecord> {
  const tableConfig: ITable<IApiKeyRecord> = {
    className: "tvd-table tvd-table-smart-table",
    data: apiKeys,
    defaultSort: "name",
    fields: [
      {
        name: "name",
        label: getTranslation("general:common.name"),
      },
      {
        name: "createdAt",
        label: getTranslation("general:common.createdAt"),
        valueFunction: (dta) => {
          if (dta.createdAt) {
            const d = new Date(dta.createdAt);
            return `${d.toLocaleDateString()}, ${d.toLocaleTimeString()}`;
          } else {
            return "";
          }
        },
        className: "centered",
      },
      {
        name: "apiKey",
        label: getTranslation("general:apiKeys.apiKey"),
        mapper: (dta) => {
          const doc = document.createDocumentFragment();

          const codeNode = document.createElement("code");
          codeNode.innerHTML = dta.apiKey;
          doc.appendChild(codeNode);

          const copyButton = document.createElement("sl-copy-button");
          copyButton.setAttribute("value", dta.apiKey);
          doc.appendChild(copyButton);

          return doc as unknown as HTMLElement;
        },
      },
    ],
    settings: {
      sortEnabled: true,
      sortVisible: true,
      filterEnabled: true,
      labelSortBy: getTranslation("general:common.sortBy"),
      labelSearch: getTranslation("general:common.filterBy"),
      filterFields: ["name", "apiKey"],
    },
    pkFunction: (dta) => dta.apiKey,
  };

  if (
    !isReadOnly &&
    systemRightToEdit &&
    securityModule.userHasPermission(systemRightToEdit)
  ) {
    tableConfig.fields.push({
      name: "apiKey",
      label: getTranslation("general:common.edit"),
      type: "icon",
      icon: "pencil",
      notSortable: true,
      onClick: (dta) => {
        onEditSaveFn(dta.apiKey, dta);
      },
    });

    tableConfig.fields.push({
      name: "apiKey",
      label: getTranslation("general:common.delete"),
      type: "icon",
      icon: "trash",
      iconVariant: "danger",
      notSortable: true,
      onClick: (dta) => {
        onDeleteFn?.(dta.apiKey, dta, "name");
      },
    });
  }

  return tableConfig;
}

function createApiKeyEdit(
  submitButton: SlButton,
  prevData?: IApiKeyRecord,
): IFormReturn<IApiKeyRecord> {
  const holder = document.createElement("div");

  const formFields: IFields<IApiKeyRecord> = [
    {
      name: "name",
      label: "general:common.name",
      type: "textBox",
      initialValue: prevData?.name || "",
    },
    {
      name: "apiKey",
      label: "general:apiKeys.apiKey",
      type: "textBox",
      initialValue: prevData?.apiKey || "",
      disabled: true,
    },
  ];

  const formValidator = z.object({
    name: z.string().min(1),
  }) as unknown as z.Schema<IApiKeyRecord>;

  const tedivoForm = new TedivoForm<IApiKeyRecord>({
    fields: formFields,
    onSubmit: () => undefined,
    formValidator: formValidator,
    submitButton: submitButton,
    autoFocusOnFirstInput: true,
    hiddenData: prevData,
  });

  translateTedivoForm<IApiKeyRecord>({
    tedivoForm,
    getTranslation: getTranslation,
  });

  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    tedivoForm,
    submitFunction: submitPassedToEditDrawer,
  };

  async function submitPassedToEditDrawer() {
    const validResult = tedivoForm.doSubmitForm();
    const values = tedivoForm.getValues();

    if (validResult.success) {
      await Services.apiKeys.update({
        name: values.name,
        apiKey: values.apiKey,
      } as IApiKeyRecord);
      return true;
    } else {
      return false;
    }
  }
}
