import "./community.component.scss";

import {
  IFile,
  IGetFilesSearch,
  systemRights,
} from "@baplie-viewer2/tedivo-api-models";
import i18n, { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import { IViewHistoryState } from "../open-json/open-json.component";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import SlCheckbox from "@shoelace-style/shoelace/dist/components/checkbox/checkbox.component";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import SlInput from "@shoelace-style/shoelace/dist/components/input/input";
import SmartTable from "../../common/smartTable/smart-table.element";
import { cloneFileDialog } from "./cloneFileDialog";
import { formatDateInCurrentTimezone } from "@baplie-viewer2/tedivo-pure-helpers";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import showCGsCell from "./showCGsCell";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";

export class TVDCommunityComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private wrapper: DivWithSpinner;
  private searchBox: HTMLDivElement;
  private cloneDialog: SlDialog;
  private dialogError: IntegratedDialogError;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.wrapper = document.createElement("div-spinner-element");
    this.searchBox = document.createElement("div");
    this.cloneDialog = document.createElement("sl-dialog");
    this.dialogError = new IntegratedDialogError(this);

    this.searchBox.className = "oss-card";

    setAppTitle(getTranslation("menu:community"));
  }

  async connectedCallback() {
    goSquared.trackPage("TVL Community Files");
    goSquared.addEvent("TVL - Show page");

    const state = router.currentState as ISearchPrevResult;

    const wrapper = this.wrapper,
      h1 = document.createElement("h1");

    const searchBox = this.searchBox;

    wrapper.appendChild(h1);
    wrapper.appendChild(searchBox);

    this.appendChild(wrapper);

    const { searchPart, searchResults } = createSearchComponent({
      initialSearchText: state?.searchText,
      selectedFields: state?.fields,
      onSearch: async (e, inputSearchBox, _searchResults, fields) => {
        e.preventDefault();

        if (inputSearchBox.value === "" || inputSearchBox.value.length < 3) {
          inputSearchBox.focus();
          return false;
        }

        try {
          const searchValue =
            (fields && fields.length === 1 && fields[0] === "imoCode") ||
            (inputSearchBox.value.length >= 7 &&
              !isNaN(Number(inputSearchBox.value)))
              ? `'${inputSearchBox.value}'`
              : inputSearchBox.value;

          const res = await wrapper.withLoading(() =>
            Services.vesselSearch.search(searchValue, fields),
          );

          const list = res.data || [];

          // Replace state
          router.navigate(
            routeFns.communityFiles(),
            {
              searchText: inputSearchBox.value,
              fields,
              data: list,
            },
            true,
          );
        } catch (err) {
          console.error(err);
          wrapper.setLoading(false);

          this.dialogError.show(
            getTranslation("errors:errorHasOcurred"),
            getTranslation("general:search.errorSearching"),
          );
        }

        return false;
      },
    });

    searchBox.appendChild(searchPart);

    if (state?.data) {
      removeChildren(searchResults);
      searchResults.appendChild(this.createResultsTable(state?.data));
    }

    // Translations
    const i18nCR = this.i18nCR;

    i18nCR.addConsumer(
      topMessageElement.element,
      "general:appName",
      "innerHTML",
    );
    i18nCR.addConsumer(h1, "general:search.communityFilesTVL", "innerHTML", {
      name: securityModule.currentOrganizationName,
    });
  }

  private createResultsTable(data: IGetFilesSearch[]): HTMLElement | Text {
    if (data.length === 0) {
      return document.createTextNode(
        getTranslation("general:search.noResults"),
      );
    }

    const canDownloadPublicFile = securityModule.planIsReadOnly
      ? undefined
      : securityModule.userHasPermission(
          systemRights.ORG.CanDownloadPublicFile,
        );

    const nn = new Intl.NumberFormat(i18n.language);

    const table = new SmartTable<IFile>().initialize({
      className: "tvd-table tvd-table-smart-table margin-top-2",
      title: `${getTranslation("general:search.searchResults")}: ${
        data.length
      }`,
      data: data.map((d) => d.item),
      fields: [
        {
          name: "name",
          label: getTranslation("general:common:name"),
        },
        {
          name: "imoCode",
          label: getTranslation("view:imoCode"),
        },
        {
          name: "callSign",
          label: getTranslation("view:callSign"),
        },
        {
          name: "cgsPercentage",
          label: getTranslation("view:cgsPercentage"),
          className: "right-aligned",
          type: "number",
          mapper: showCGsCell,
        },
        {
          name: "hasHatchCovers",
          label: getTranslation("view:hasHatchCovers"),
          valueFunction: (dta) =>
            dta.hasHatchCovers
              ? getTranslation("general:common.yes")
              : getTranslation("general:common.no"),
          className: "centered",
          fixedWidth: "100px",
        },
        {
          name: "teus",
          label: "TEUs",
          type: "number",
          valueFunction: (dta) => (dta.teus ? nn.format(dta.teus) : "N/A"),
          className: "centered",
          fixedWidth: "100px",
        },
        {
          name: "organizationName",
          label: getTranslation("general:common.organizationName"),
          className: (dta) =>
            dta?.organizationId === securityModule.currentOrganizationId
              ? "state-success centered"
              : "centered",
        },
        {
          name: "downloads",
          label: getTranslation("general:common.downloads"),
          className: "centered",
        },
        {
          name: "votes",
          label: getTranslation("general:common.votes"),
          className: "centered",
        },
        {
          name: "lastModified",
          label: getTranslation("general:common:lastUpdated"),
          valueFunction: (dta: IFile) =>
            formatDateInCurrentTimezone(dta.lastModified),
          className: "centered",
        },
        {
          name: "fileId",
          label: getTranslation("general:common.view"),
          type: "icon",
          icon: (dta) =>
            dta.organizationId === securityModule.currentOrganizationId
              ? "pencil"
              : "eye",
          notSortable: true,
          onClick: (dta: IFile) => {
            if (dta.organizationId === securityModule.currentOrganizationId) {
              router.navigate(routeFns.ovdEdit(dta.fileId));
              return;
            }

            const viewState: IViewHistoryState = {
              source: "cloudOvdJson",
              cloudId: dta.fileId,
              organizationId: dta.organizationId,
              organizationName: dta.organizationName,
              impersonate: true,
            };

            goSquared.addEvent("Community - View public file");
            router.navigate(routeFns.ovdViewOnly(dta.fileId), viewState);
            // }
          },
        },

        {
          name: "fileId",
          label: getTranslation("general:search.cloneItToMyAccount"),
          type: "icon",
          icon: "cloud-download-fill",
          notSortable: true,
          iconVariant: canDownloadPublicFile ? "primary" : "neutral",
          disabled: (dta) =>
            dta.organizationId === securityModule.currentOrganizationId,
          onClick: (dta) => {
            if (canDownloadPublicFile) {
              this.cloneToMyAccount(dta);
            } else {
              this.dialogError.show(
                getTranslation("general:common.attention"),
                getTranslation("general:search.cloneOnlyForPaidAccounts"),
              );
            }
          },
        },
      ],
      settings: {
        sortEnabled: true,
        sortVisible: true,
        filterEnabled: true,
        labelSearch: getTranslation("general:common.filterBy"),
        labelSortBy: getTranslation("general:common.sortBy"),
        createSortIconFn: createSortIcon,
      },
      pkFunction: (dta) => dta.fileId,
    });

    return table;
  }

  private cloneToMyAccount = async (
    dta: Pick<IFile, "name" | "organizationId" | "fileId">,
  ) => {
    cloneFileDialog({
      onlyCommunity: true,
      actionLabel: "general:search.cloneItToMyAccount",
      goSquaredLabel: "TVL - Clone file",
      modal: this.cloneDialog,
      fileData: dta,
    });

    this.appendChild(this.cloneDialog);
  };
}

customElements.define("tvd-community-component", TVDCommunityComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-community-component": TVDCommunityComponent;
  }
}

const createSortIcon = (asc: boolean) => {
  const icon = document.createElement("sl-icon");
  icon.name = asc ? "sort-down-alt" : "sort-up-alt";
  return icon;
};

function createSearchComponent({
  onSearch,
  initialSearchText,
  selectedFields,
}: {
  onSearch: (
    e: SubmitEvent,
    inputSearchBox: SlInput,
    searchResults: HTMLDivElement,
    fields: string[],
  ) => void;
  initialSearchText?: string;
  selectedFields?: string[];
}) {
  const FIELDS_AVAILABLE = ["name", "imoCode", "callSign"];
  const checkboxes: Array<SlCheckbox> = [];

  const searchPart = document.createElement("div");
  searchPart.className = "my-cloud-search-part";

  const searchResults = document.createElement("div");

  const inputSearchBox = document.createElement("sl-input");
  inputSearchBox.type = "search";
  inputSearchBox.className = "my-cloud-search-input";
  inputSearchBox.placeholder = getTranslation(
    "general:search.searchInputPlaceholder",
  );
  inputSearchBox.label = getTranslation("general:search.searchInputLabel");
  inputSearchBox.helpText = getTranslation("general:search.minChars");
  inputSearchBox.value = initialSearchText || "";

  const searchBtn = document.createElement("sl-button");
  searchBtn.innerHTML = getTranslation("general:search.action");
  searchBtn.variant = "primary";
  searchBtn.type = "submit";

  const form = document.createElement("form");

  const fieldsChks = document.createElement("div");
  fieldsChks.id = "fields";
  fieldsChks.className = "fields-chks";

  FIELDS_AVAILABLE.forEach((f) => {
    const chk = document.createElement("sl-checkbox");
    chk.name = f;
    chk.value = f;
    if (selectedFields?.includes(f)) chk.checked = true;
    chk.innerHTML = getTranslation(`view:${f}`);
    fieldsChks.appendChild(chk);
    checkboxes.push(chk);
    chk.addEventListener("sl-change", setUndeterminateInCheckboxes);
  });

  form.appendChild(inputSearchBox);
  form.appendChild(fieldsChks);
  form.appendChild(searchBtn);
  setUndeterminateInCheckboxes();

  searchPart.appendChild(form);
  searchPart.appendChild(searchResults);

  form.addEventListener("submit", (e) => {
    onSearch(
      e,
      inputSearchBox,
      searchResults,
      checkboxes.map((chk) => (chk.checked ? chk.value : "")).filter(Boolean),
    );
  });

  return { searchPart, searchResults };

  function setUndeterminateInCheckboxes() {
    const checked = checkboxes.filter((chk) => chk.checked);

    if (checked.length === 0) {
      checkboxes.forEach((chk) => (chk.indeterminate = true));
    } else {
      checkboxes.forEach((chk) => (chk.indeterminate = false));
    }
  }
}

interface ISearchPrevResult {
  searchText: string;
  fields: string[];
  data: IGetFilesSearch[];
}
