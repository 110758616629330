import { __awaiter } from "tslib";
import { systemRights, } from "@baplie-viewer2/tedivo-api-models";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import { createErrorNotification, createSuccessNotification, } from "../../../app/notifications/createNotification";
import { ListComponentWithActions } from "../../common/ListComponentWithActions";
import Services from "../../../app/services";
import { getTranslation } from "../../../app/i18/i18tn";
import goSquared from "../../../app/tracking/goSquared";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import { z } from "zod";
export class TVDAdminDomainsComponent extends ListComponentWithActions {
    constructor() {
        super();
        this.h1Label = "general:domains.title";
        this.goSquaredLabel = "Admin/Domains";
        this.topLabel = "general:adminArea";
        this.createLabel = "general:domains.adminCreate";
        this.deleteLabel = "general:domains.delete";
        this.serviceListCall = Services.domains.getAll;
        this.serviceDeleteCall = Services.domains.delete;
        this.serviceEditCall = Services.domains.update;
        this.systemRightToEdit = systemRights.ORG.AppAdminDomains;
        this.tableConfig = (data) => createDomainsTableConfig(data, this.systemRightToEdit, this.openEditModal, this.openDeleteModal);
        this.createEditForm = createDomainEdit;
        setAppTitle(getTranslation("general:domains.title"));
        goSquared.trackPage("TEDIVO Admin Pages - Domains");
    }
}
TVDAdminDomainsComponent.observedAttributes = [];
customElements.define("tvd-admin-domains-component", TVDAdminDomainsComponent);
function createDomainsTableConfig(domains, systemRightToEdit, onEditSaveFn, onDeleteFn) {
    const tableConfig = {
        className: "tvd-table tvd-table-smart-table",
        data: domains,
        defaultSort: "domain",
        fields: [
            {
                name: "domain",
                label: getTranslation("general:domains.domain"),
            },
            {
                name: "allowed",
                label: getTranslation("general:domains.attrAllowed"),
                type: "inlineToggle",
                className: "centered",
                getValueFn: (d) => d.allowed === 1,
                onToggle: (d, _, table, value) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const resp = yield Services.domains.updateAllowed(d.domain, value);
                        if (resp.statusCode !== 200 && resp.statusCode !== 304) {
                            createErrorNotification(getTranslation("errors:domainUnableToUpdate"), 4000);
                        }
                        else {
                            createSuccessNotification(`${d.domain}, ${getTranslation("general:domains.attrAllowed")}: ${getTranslation(`general:common.${value ? "yes" : "no"}`)}`, 3000);
                        }
                        if (resp.data)
                            table.updateRowWithNewData(d.domain, resp.data);
                    }
                    catch (e) {
                        console.error(e);
                    }
                }),
                fixedWidth: "100px",
            },
            {
                name: "abused",
                label: getTranslation("general:domains.attrAbused"),
                type: "inlineToggle",
                className: "centered",
                getValueFn: (d) => d.abused === 1,
                onToggle: (d, _, table, value) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const resp = yield Services.domains.updateAbused(d.domain, value);
                        if (resp.statusCode !== 200 && resp.statusCode !== 304) {
                            createErrorNotification(getTranslation("errors:domainUnableToUpdate"), 4000);
                        }
                        else {
                            createSuccessNotification(`${d.domain}, ${getTranslation("general:domains.attrAbused")}: ${getTranslation(`general:common.${value ? "yes" : "no"}`)}`, 3000);
                        }
                        if (resp.data)
                            table.updateRowWithNewData(d.domain, resp.data);
                    }
                    catch (e) {
                        console.error(e);
                    }
                }),
                fixedWidth: "100px",
            },
            // {
            //   name: "allowed",
            //   label: getTranslation("general:domains.attrAllowed"),
            //   mapper: (v) => yesNoMapper(v.allowed),
            //   className: "centered",
            // },
            // {
            //   name: "abused",
            //   label: getTranslation("general:domains.attrAbused"),
            //   mapper: (v) => yesNoMapper(v.abused),
            //   className: "centered",
            //   fixedWidth: "100px",
            // },
            {
                name: "modifiedAt",
                label: getTranslation("general:common.modifiedAt"),
                valueFunction: (dta) => {
                    if (dta.modifiedAt) {
                        const d = new Date(dta.modifiedAt);
                        return `${d.toLocaleDateString()}, ${d.toLocaleTimeString()}`;
                    }
                    else {
                        return "";
                    }
                },
                className: "centered",
                fixedWidth: "200px",
            },
        ],
        settings: {
            sortEnabled: true,
            sortVisible: true,
            filterEnabled: true,
            labelSortBy: getTranslation("general:common.sortBy"),
            labelSearch: getTranslation("general:common.filterBy"),
            filterFields: ["domain"],
        },
        pkFunction: (dta) => dta.domain,
    };
    if (systemRightToEdit &&
        securityModule.userHasPermission(systemRightToEdit)) {
        tableConfig.fields.push({
            name: "domain",
            label: getTranslation("general:common.edit"),
            type: "icon",
            icon: "pencil",
            fixedWidth: "80px",
            notSortable: true,
            onClick: (dta) => {
                onEditSaveFn(dta.domain, dta);
            },
        });
        tableConfig.fields.push({
            name: "domain",
            label: getTranslation("general:common.delete"),
            type: "icon",
            icon: "trash",
            iconVariant: "danger",
            fixedWidth: "80px",
            notSortable: true,
            onClick: (dta) => {
                onDeleteFn === null || onDeleteFn === void 0 ? void 0 : onDeleteFn(dta.domain, dta, "domain");
            },
        });
    }
    return tableConfig;
}
function createDomainEdit(submitButton, prevData) {
    const holder = document.createElement("div");
    const formFields = [
        {
            name: "domain",
            label: "general:domains.domain",
            type: "textBox",
            initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.domain) || "",
        },
        {
            name: "allowed",
            label: "general:domains.attrAllowed",
            type: "checkbox",
            initialValue: !!(prevData === null || prevData === void 0 ? void 0 : prevData.allowed),
        },
        {
            name: "abused",
            label: "general:domains.attrAbused",
            type: "checkbox",
            initialValue: !!(prevData === null || prevData === void 0 ? void 0 : prevData.abused),
        },
    ];
    const formValidator = z.object({
        domain: z.string().min(1),
    });
    const tedivoForm = new TedivoForm({
        fields: formFields,
        onSubmit: () => undefined,
        formValidator: formValidator,
        submitButton: submitButton,
        autoFocusOnFirstInput: true,
        hiddenData: prevData,
    });
    translateTedivoForm({
        tedivoForm,
        getTranslation: getTranslation,
    });
    holder.appendChild(tedivoForm.form);
    return {
        node: holder,
        tedivoForm,
        submitFunction: submitPassedToEditDrawer,
    };
    function submitPassedToEditDrawer() {
        return __awaiter(this, void 0, void 0, function* () {
            const validResult = tedivoForm.doSubmitForm();
            const values = tedivoForm.getValues();
            if (validResult.success) {
                yield Services.domains.update({
                    domain: values.domain,
                    allowed: values.allowed ? 1 : 0,
                    abused: values.abused ? 1 : 0,
                    modifiedAt: new Date(),
                });
                return true;
            }
            else {
                return false;
            }
        });
    }
}
function yesNoMapper(v) {
    return getTranslation(`general:common.${v == 1 ? "yes" : "no"}`);
}
