/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "@shoelace-style/shoelace/dist/components/alert/alert";
import "@shoelace-style/shoelace/dist/components/avatar/avatar";
import "@shoelace-style/shoelace/dist/components/badge/badge";
import "@shoelace-style/shoelace/dist/components/button-group/button-group";
import "@shoelace-style/shoelace/dist/components/button/button";
import "@shoelace-style/shoelace/dist/components/checkbox/checkbox";
import "@shoelace-style/shoelace/dist/components/details/details";
import "@shoelace-style/shoelace/dist/components/dialog/dialog";
import "@shoelace-style/shoelace/dist/components/divider/divider";
import "@shoelace-style/shoelace/dist/components/drawer/drawer";
import "@shoelace-style/shoelace/dist/components/dropdown/dropdown";
import "@shoelace-style/shoelace/dist/components/icon-button/icon-button";
import "@shoelace-style/shoelace/dist/components/icon/icon";
import "@shoelace-style/shoelace/dist/components/input/input";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item";
import "@shoelace-style/shoelace/dist/components/menu/menu";
import "@shoelace-style/shoelace/dist/components/option/option";
import "@shoelace-style/shoelace/dist/components/qr-code/qr-code";
import "@shoelace-style/shoelace/dist/components/radio-button/radio-button";
import "@shoelace-style/shoelace/dist/components/radio-group/radio-group";
import "@shoelace-style/shoelace/dist/components/radio/radio";
import "@shoelace-style/shoelace/dist/components/select/select";
import "@shoelace-style/shoelace/dist/components/spinner/spinner";
import "@shoelace-style/shoelace/dist/components/switch/switch";
import "@shoelace-style/shoelace/dist/components/tab/tab";
import "@shoelace-style/shoelace/dist/components/tab-group/tab-group";
import "@shoelace-style/shoelace/dist/components/tab-panel/tab-panel";
import "@shoelace-style/shoelace/dist/components/textarea/textarea";
import "@shoelace-style/shoelace/dist/components/tooltip/tooltip";
import "@shoelace-style/shoelace/dist/components/copy-button/copy-button";
import "./app.element.scss";

import ICustomError from "../components/types/ICustomError";
import IntegratedDialogError from "../components/common/IntegratedDialogError";
import { TVDColorModeElement } from "../components/layout/top-tools/tvd-color-mode.element";
import { browserIsNotSupported } from "@baplie-viewer2/tedivo-dom-helpers";
import { errorTracking } from "./tracking/errorTracking";
import { getTranslation } from "./i18/i18tn";
import goSquared from "./tracking/goSquared";
import googleRecaptcha from "./tracking/googleRecaptcha";
import googleTags from "./tracking/googleTags";
import msClarity from "./tracking/msClarity";
import { routeFns } from "./router/routes";
import router from "./router";
import securityModule from "./security/SecurityModule";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js";
import { setDefaultAnimation } from "@shoelace-style/shoelace/dist/utilities/animation-registry";

setBasePath("assets/shoelace");

// Override animation of drawer
setDefaultAnimation("drawer.showEnd", {
  keyframes: [
    { opacity: 0, transform: "translateX(0)" },
    { opacity: 1, transform: "translateX(0)" },
  ],
  rtlKeyframes: [
    { opacity: 0, transform: "translateX(0)" },
    { opacity: 1, transform: "translateX(0)" },
  ],
  options: { duration: 0, easing: "ease" },
});

setDefaultAnimation("drawer.denyClose", {
  keyframes: [{ scale: 1 }],
  options: { duration: 0 },
});

export class AppElement extends HTMLElement {
  public static observedAttributes = [];
  private dialogError: IntegratedDialogError = new IntegratedDialogError(this);

  constructor() {
    super();
    this.initializeTrackers();

    const browserError = browserIsNotSupported(navigator.userAgent);

    if (browserError !== "") {
      console.log(browserError);
      const sb = document.getElementById("unsupported-browser")!;
      sb.style.display = "flex";
      sb.appendChild(document.createTextNode(browserError));

      document.getElementById("tvd-editor-root")!.remove();
    } else {
      window.setTimeout(async () => {
        securityModule.checkIfUserIsLoggedIn();
      }, 0);

      securityModule.addEventListener("httpCallProhibited", () => {
        router.navigate(routeFns.myCloud());
      });
    }

    document.body.addEventListener("customError", (ev) => {
      const { detail } = ev as CustomEvent<ICustomError>;
      this.showError(detail);
    });
  }

  initializeTrackers() {
    // Bugsnag
    if (process.env.NX_PUBLIC_BUGSNAG_ID)
      errorTracking.start(
        process.env.NX_PUBLIC_BUGSNAG_ID,
        process.env.NX_PUBLIC_STAGE,
      );

    // GoSquared
    if (process.env.NX_PUBLIC_GOSQUARED_ID)
      goSquared.initialize(process.env.NX_PUBLIC_GOSQUARED_ID);

    // Ms Clarity
    if (process.env.NX_PUBLIC_MSCLARITY_ID)
      msClarity.initialize(process.env.NX_PUBLIC_MSCLARITY_ID);

    // Google Tags
    if (process.env.NX_PUBLIC_GOOGLE_ID)
      googleTags.initialize(process.env.NX_PUBLIC_GOOGLE_ID);

    googleRecaptcha.initialize("v2");
  }

  connectedCallback() {
    const contentHolder = document.createElement("div"),
      content = document.createElement("div"),
      main = document.createElement("main"),
      sidebar = document.createElement("aside"),
      logo = document.createElement("img"),
      topTools = document.createElement("tvd-top-tools-component"),
      timeOutDialog = document.createElement("sl-dialog");

    content.id = "st-main-content";

    sidebar.className = "st-sidebar";
    sidebar.id = "st-sidebar";
    content.className = "st-content";
    contentHolder.className = "st-content-holder";

    logo.className = "st-main-logo";
    logo.src = "assets/images/favicon.svg";
    logo.alt = getTranslation("general:appName");
    logo.title = getTranslation("general:appName");
    logo.setAttribute("role", "branding");
    logo.addEventListener("click", () => router.navigate(routeFns.myCloud()));

    document.documentElement.addEventListener("colorModeChanged", (ev) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const resolvedMode = (ev as any).detail.mode;
      logo.src = setResolvedLogoSrc(resolvedMode);
    });

    sidebar.appendChild(logo);

    // Menu
    const menu = document.createElement("tvd-menu-component");
    menu.onOptionSelected = (action: string) => {
      router.navigate(action);
    };
    sidebar.appendChild(menu);

    // Timeout Dialog
    timeOutDialog.noHeader = true;
    timeOutDialog.addEventListener("sl-request-close", (event: Event) => {
      if ((event as CustomEvent).detail.source === "overlay") {
        event.preventDefault();
      }
    });

    contentHolder.appendChild(topTools);
    contentHolder.appendChild(content);
    contentHolder.appendChild(timeOutDialog);

    main.appendChild(sidebar);
    main.appendChild(contentHolder);

    this.appendChild(main);

    setTimeout(() => {
      // Start the router to display a component
      router.addMiddleware((url: string) => {
        if (
          securityModule.isLoggedIn &&
          securityModule.organizationMfaForAllUsers &&
          !securityModule.isMfaEnabled
        ) {
          return routeFns.login(undefined, true);
        } else {
          return url;
        }
      });

      router.start();

      const toolColor = document.getElementById(
        "color-mode-select",
      ) as TVDColorModeElement;
      if (toolColor) {
        logo.src = setResolvedLogoSrc(toolColor.resolvedMode);
      }
    }, 0);
  }

  showError = (e: ICustomError) => {
    this.dialogError.show(
      typeof e === "string"
        ? getTranslation(`errors:errorHasOcurred`)
        : getTranslation(e.message),
      getTranslation(typeof e === "string" ? e : e.translationKey),
    );
  };
}

customElements.define("tvd-editor-root", AppElement);

function setResolvedLogoSrc(resolvedMode: "dark" | "light" | "auto") {
  return `assets/images/Icon_Designer-Icon-${
    resolvedMode === "light" ? "color" : "white"
  }.svg`;
}

export function setAppTitle(title: string) {
  if (!document) return;

  document.title = `${title} - TVD`;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Bugsnag: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    awsCognito: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tvdServices: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    xson: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _gs: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    grecaptcha: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    securityModule: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tvdBeaconServices: any;

    onloadCallback: () => void;
  }
}
