import { __awaiter } from "tslib";
import "./comparer-component.scss";
import { ComparerTypesEnum, getComparerTypesFromRouteKey, } from "./ComparerTypesEnum";
import { createSelectShoelace, } from "@baplie-viewer2/tedivo-form";
import Services from "../../../app/services";
import { createVersionsDropdown } from "../../helpers/createVersionsDropdown";
import { formatDateInCurrentTimezone } from "@baplie-viewer2/tedivo-pure-helpers";
import { getTranslation } from "../../../app/i18/i18tn";
import goSquared from "../../../app/tracking/goSquared";
import { openOvdInTvd } from "../../../app/helpers/openOvdInTvd";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
export class TVDFilesComparer extends HTMLElement {
    constructor() {
        super();
        this.confirmDialog = undefined;
        this.holder = document.createElement("div-spinner-element");
        this.divContent = document.createElement("div");
        this.holder.classList.add("comparer-holder");
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const holder = this.holder;
            this.appendChild(holder);
            if (topMessageElement.element)
                topMessageElement.element.innerText =
                    securityModule.currentOrganizationName;
            const comparerType = getComparerTypesFromRouteKey(router.currentRouteKey);
            const id = router.getRouteParams().id;
            const version = router.getRouteParams().version;
            const orgId = ((_a = router.currentState) === null || _a === void 0 ? void 0 : _a.organizationId) ||
                securityModule.currentOrganizationId;
            const [jsonComparison, versionsResponse] = yield Promise.all([
                this.getComparisonData(comparerType, id, version),
                comparerType === ComparerTypesEnum.OWN_VERSION && orgId && id
                    ? Services.files.getFileVersions(orgId, id)
                    : Promise.resolve(undefined),
            ]);
            if (comparerType === undefined ||
                id === undefined ||
                jsonComparison === undefined) {
                router.navigate(routeFns.myCloud());
                return;
            }
            goSquared.trackPage(TVDFilesComparer.optionsByComparerType[comparerType].pageTrackTitle);
            goSquared.addEvent(`${TVDFilesComparer.optionsByComparerType[comparerType].pageTrackTitle} - Show page`);
            setAppTitle(getTranslation("view:comparer.pageTitle", {
                vesselName: (jsonComparison === null || jsonComparison === void 0 ? void 0 : jsonComparison.vesselName) || "?",
            }));
            this.render(id, version, orgId, jsonComparison, comparerType, versionsResponse);
            holder.setLoading(false);
        });
    }
    getComparisonData(comparerType, id, version) {
        return __awaiter(this, void 0, void 0, function* () {
            if (comparerType === undefined || !id) {
                document.body.dispatchEvent(new CustomEvent("customError", {
                    detail: {
                        errorCode: "",
                        message: "errors:noIdProvided",
                        translationKey: "errors:pleaseProvideAValidId",
                    },
                }));
                return undefined;
            }
            let data = undefined;
            try {
                this.holder.setLoading(true);
                switch (comparerType) {
                    case ComparerTypesEnum.TVL_SOURCE:
                        data = yield Services.files.compareSourceTvl(id);
                        break;
                    case ComparerTypesEnum.OWN_VERSION:
                        data = yield Services.files.compareOwnVersion(id, version);
                        break;
                    case ComparerTypesEnum.TVL_CONSUMER:
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const consumerOrgId = router.getRouteParams().consumerOrgId;
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const consumerFileId = router.getRouteParams().consumerFileId;
                        data = yield Services.files.compareConsumerTvl({
                            sourceFileId: id,
                            consumerOrgId,
                            consumerFileId,
                        });
                        break;
                }
                this.holder.setLoading(false);
                if (!data || !data.data) {
                    throw new Error("DB error");
                }
                return data.data;
            }
            catch (e) {
                if ((data === null || data === void 0 ? void 0 : data.statusCode) !== 402)
                    document.body.dispatchEvent(new CustomEvent("customError", {
                        detail: {
                            errorCode: "",
                            message: e,
                            translationKey: "errors:cannotGetTheData",
                        },
                    }));
                this.holder.setLoading(false);
                return undefined;
            }
        });
    }
    render(fileId, version, orgId, json, comparerType, versions) {
        removeChildren(this.divContent);
        if (!fileId)
            return;
        const titleWithActions = document.createElement("div");
        titleWithActions.className = "title-with-action";
        const h1 = document.createElement("h1");
        h1.innerHTML = getTranslation(TVDFilesComparer.optionsByComparerType[comparerType].h1Title, {
            vesselName: json.vesselName,
            consumerName: json.sourceOrgName,
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const consumerOrgId = router.getRouteParams().consumerOrgId;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const consumerFileId = router.getRouteParams().consumerFileId;
        const topActionsDropdown = createSelectShoelace({
            id: "actions",
            caret: false,
            ommitCheckSign: true,
            variant: "primary",
            size: "small",
            buttonText: getTranslation("view:comparer.updateActions"),
            iconPrefix: "clipboard-check",
            options: TVDFilesComparer.optionsByComparerType[comparerType].actionsOptions,
            onChange: (v) => __awaiter(this, void 0, void 0, function* () {
                switch (v) {
                    // SOURCE
                    case "viewTvl":
                        openOvdInTvd({
                            fileId: json.sourceId,
                            organizationId: json.sourceOrgId,
                            organizationName: json.sourceOrgName,
                            name: json.vesselName,
                        }, undefined);
                        break;
                    case "ignoreSourceUpdate":
                        this.holder.setLoading(true);
                        yield Services.files.ignoreCloneDiffers(fileId);
                        this.holder.setLoading(false);
                        router.navigate(routeFns.myCloud());
                        break;
                    case "update":
                        this.confirmReplace({
                            title: "view:edit.cloneModified.update",
                            text: "view:edit.cloneModified.confirm",
                            serviceCb: () => __awaiter(this, void 0, void 0, function* () {
                                yield Services.files.updateWithSource(fileId);
                            }),
                            onFinished: () => router.navigate(routeFns.myCloud()),
                        });
                        break;
                    // OWN VERSIONS
                    case "viewCurrentVersion":
                        router.navigate(routeFns.ovdEdit(fileId));
                        break;
                    case "viewOldVersion":
                        if (!version)
                            return router.navigate(routeFns.ovdEdit(fileId));
                        router.navigate(routeFns.ovdViewOnlyVersion(fileId, version), {
                            organizationId: json.sourceOrgId,
                            cloudId: fileId,
                            source: "cloudOvdJson",
                        });
                        break;
                    case "replaceWithOldVersion":
                        if (!version)
                            return;
                        this.confirmReplace({
                            title: "view:comparer.replaceWithOldVersion",
                            text: "view:comparer.replaceWithOldVersion",
                            serviceCb: () => __awaiter(this, void 0, void 0, function* () {
                                yield Services.files.updateWithVersion(fileId, version);
                            }),
                            onFinished: () => router.navigate(routeFns.ovdEdit(fileId)),
                        });
                        break;
                    // CONSUMERS
                    case "viewConsumer":
                        openOvdInTvd({
                            organizationId: consumerOrgId,
                            fileId: consumerFileId,
                            name: json.vesselName,
                            organizationName: json.sourceOrgName,
                        }, undefined);
                        break;
                    case "updateWithConsumer":
                        yield Services.files.updateWithConsumer({
                            sourceFileId: fileId,
                            consumerOrgId: consumerOrgId,
                            consumerFileId: consumerFileId,
                        });
                        router.navigate(routeFns.ovdEdit(fileId));
                        break;
                    case "ignoreConsumerUpdate":
                    case "ignoreConsumerFile":
                        yield Services.files.ignoreConsumerDiffers({
                            sourceOrgId: json.sourceOrgId,
                            sourceFileId: fileId,
                            consumerOrgId: consumerOrgId,
                            consumerFileId: consumerFileId,
                            ignoreType: v === "ignoreConsumerFile" ? "all" : "current",
                        });
                        router.navigate(routeFns.fileMap(fileId), undefined, true);
                        break;
                }
            }),
        });
        titleWithActions.appendChild(h1);
        titleWithActions.appendChild(createTvlMapButton(orgId, fileId));
        titleWithActions.appendChild(topActionsDropdown);
        this.holder.appendChild(titleWithActions);
        this.holder.appendChild(this.divContent);
        const selectVersionNode = createVersionsDropdown({
            selectedVersion: version || "",
            addCurrentVersion: false,
            versionsResponse: versions,
            onChange: (v, prev) => __awaiter(this, void 0, void 0, function* () {
                if (v === prev)
                    return;
                router.navigate(routeFns.fileCompareOwnVersion(fileId, encodeURI(v)), {
                    organizationId: orgId,
                    cloudId: fileId,
                });
            }),
        });
        writeDataToElements(this.divContent, json, selectVersionNode);
    }
    confirmReplace({ title, text, serviceCb, onFinished, }) {
        if (!this.confirmDialog) {
            this.confirmDialog = document.createElement("sl-dialog");
            this.appendChild(this.confirmDialog);
        }
        else {
            removeChildren(this.confirmDialog);
        }
        const changeOkButton = document.createElement("sl-button");
        changeOkButton.slot = "footer";
        changeOkButton.innerHTML = getTranslation(`general:common.ok`);
        changeOkButton.variant = "primary";
        changeOkButton.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.confirmDialog.hide();
            try {
                this.holder.setLoading(true);
                yield serviceCb();
                this.holder.setLoading(false);
                onFinished();
            }
            catch (e) {
                document.body.dispatchEvent(new CustomEvent("customError", {
                    detail: {
                        errorCode: "",
                        message: "errors:errorHasOcurred",
                        translationKey: "errors:unableToUpdateFile",
                    },
                }));
            }
        }));
        this.confirmDialog.label = getTranslation(title);
        this.confirmDialog.appendChild(document.createTextNode(getTranslation(text)));
        this.confirmDialog.appendChild(changeOkButton);
        this.confirmDialog.show();
    }
}
TVDFilesComparer.observedAttributes = [];
TVDFilesComparer.optionsByComparerType = {
    [ComparerTypesEnum.TVL_SOURCE]: {
        pageTrackTitle: "File Comparer - TVL Source",
        h1Title: "view:comparer.title",
        actionsOptions: [
            {
                icon: "eye",
                name: getTranslation("view:edit.cloneModified.seeNewVersion"),
                value: "viewTvl",
            },
            {
                icon: "clipboard-x",
                name: getTranslation("view:edit.cloneModified.ignoreUpdate"),
                value: "ignoreSourceUpdate",
            },
            {
                icon: "cloud-arrow-down",
                name: getTranslation("view:edit.cloneModified.update"),
                value: "update",
            },
        ],
    },
    [ComparerTypesEnum.OWN_VERSION]: {
        pageTrackTitle: "File Comparer - Own Version",
        h1Title: "view:comparer.titleWithVersion",
        actionsOptions: [
            {
                icon: "eye",
                name: getTranslation("view:comparer.viewCurrentVersion"),
                value: "viewCurrentVersion",
            },
            {
                icon: "eye",
                name: getTranslation("view:comparer.viewOldVersion"),
                value: "viewOldVersion",
            },
            {
                icon: "reply",
                name: getTranslation("view:comparer.replaceWithOldVersion"),
                value: "replaceWithOldVersion",
            },
        ],
    },
    [ComparerTypesEnum.TVL_CONSUMER]: {
        pageTrackTitle: "File Comparer - Consumer",
        h1Title: "view:comparer.consumer",
        actionsOptions: [
            {
                name: getTranslation("view:edit.consumerUpdated.seeNewVersion"),
                value: "viewConsumer",
                icon: "eye",
            },
            {
                name: getTranslation("view:edit.consumerUpdated.ignoreUpdate"),
                value: "ignoreConsumerUpdate",
                icon: "clipboard-x",
            },
            {
                name: getTranslation("view:edit.consumerUpdated.ignoreFile"),
                value: "ignoreConsumerFile",
                icon: "clipboard-x",
            },
            {
                name: getTranslation("view:edit.consumerUpdated.update"),
                value: "updateWithConsumer",
                icon: "cloud-arrow-down",
            },
        ],
    },
};
customElements.define("tvd-comparer-component", TVDFilesComparer);
function createTvlMapButton(orgId, fileId) {
    const btnTvlMap = document.createElement("sl-button");
    btnTvlMap.innerHTML = getTranslation("view:fileMap.tvlMap");
    btnTvlMap.size = "small";
    btnTvlMap.pill = true;
    btnTvlMap.variant = "primary";
    btnTvlMap.title = getTranslation("view:fileMap.tvlMap");
    btnTvlMap.outline = true;
    btnTvlMap.addEventListener("click", () => {
        router.navigate(routeFns.fileMap(fileId), {
            organizationId: orgId,
        });
    });
    const icon = document.createElement("sl-icon");
    icon.name = "geo";
    icon.slot = "prefix";
    btnTvlMap.appendChild(icon);
    return btnTvlMap;
}
function writeDataToElements(holder, json, selectVersion) {
    var _a;
    if (!json)
        return { topRightCell: undefined };
    const table = document.createElement("table");
    const tbody = document.createElement("tbody");
    const thead = document.createElement("thead");
    table.setAttribute("cellspacing", "5");
    table.appendChild(thead);
    table.appendChild(tbody);
    const { tr, leftCell, rightCell } = createnodes(true);
    leftCell.innerHTML = getTranslation("view:comparer.target", {
        target: json.vesselName,
        date: formatDateInCurrentTimezone(new Date(json.targetDate)),
    });
    if (!selectVersion) {
        rightCell.innerHTML = getTranslation("view:comparer.tvlSource", {
            date: formatDateInCurrentTimezone(new Date(json.sourceDate)),
        });
    }
    else {
        rightCell.appendChild(selectVersion);
    }
    thead.appendChild(tr);
    (_a = json.diffs) === null || _a === void 0 ? void 0 : _a.forEach((d) => {
        if (!d)
            return;
        const { tr, h2, leftCell, rightCell } = createnodes();
        h2.innerHTML = getTranslation(`view:comparer.${d.key}`, d.keyData);
        drawLines(d.source, d.target, leftCell, rightCell);
        tbody.appendChild(tr);
    });
    removeChildren(holder);
    holder.appendChild(table);
    return {
        topRightCell: rightCell,
    };
    function createnodes(isHeader = false) {
        const tr = document.createElement("tr");
        const h2 = document.createElement(isHeader ? "th" : "td");
        const leftCell = document.createElement(isHeader ? "th" : "td");
        const rightCell = document.createElement(isHeader ? "th" : "td");
        tr.appendChild(h2);
        tr.appendChild(leftCell);
        tr.appendChild(rightCell);
        holder.appendChild(tr);
        if (!isHeader) {
            h2.classList.add("comparer-header");
            leftCell.classList.add("comparer-code");
            rightCell.classList.add("comparer-code");
        }
        else {
            tr.classList.add("comparer-head-row");
        }
        return { tr, h2, leftCell, rightCell };
    }
}
function drawLines(dLeft, dRight, divLeft, divRight) {
    if (dLeft === undefined || dRight === undefined) {
        divLeft.innerHTML = "-";
        divRight.innerHTML = "-";
        return;
    }
    const maxLines = Math.max(dLeft.length, dRight.length);
    let iLeft = 0, iRight = 0;
    while (iLeft < maxLines || iRight < maxLines) {
        const dLeftItem = dLeft[iLeft];
        const dRightItem = dRight[iRight];
        if (dLeftItem === undefined && dRightItem === undefined) {
            break;
        }
        const dLeftType = dLeftItem === null || dLeftItem === void 0 ? void 0 : dLeftItem.type;
        const dRightType = dRightItem === null || dRightItem === void 0 ? void 0 : dRightItem.type;
        const lineDivLeft = document.createElement("div");
        const lineDivRight = document.createElement("div");
        if ((dLeftType === "equal" || dLeftType === "modify") &&
            (dRightType === "equal" || dRightType === "modify")) {
            const padStart = String().padStart(dLeftItem.level * 2, " ");
            lineDivLeft.innerHTML =
                padStart +
                    (dLeftType === "modify" ? "<span class='stroked'>" : "") +
                    dLeftItem.text +
                    (dLeftType === "modify" ? "</span>" : "") +
                    (dLeftItem.comma ? "," : "");
            lineDivRight.innerHTML =
                padStart + dRightItem.text + (dRightItem.comma ? "," : "");
            iLeft++;
            iRight++;
        }
        else {
            // Here one is "remove" and the other is "equal"
            if (dLeftType === "remove" && dLeftItem) {
                const padStart = String().padStart(dLeftItem.level * 2, " ");
                lineDivLeft.innerHTML =
                    padStart + dLeftItem.text + (dLeftItem.comma ? "," : "");
                lineDivRight.innerHTML = " ";
                lineDivRight.classList.add(`comparer-line-missing`);
            }
            else if (dRightType === "remove" && dRightItem) {
                const padStart = String().padStart(dRightItem.level * 2, " ");
                lineDivLeft.innerHTML = " ";
                lineDivLeft.classList.add(`comparer-line-missing`);
                lineDivRight.innerHTML =
                    padStart + dRightItem.text + (dRightItem.comma ? "," : "");
            }
            else if (dLeftType === "add" && dLeftItem) {
                const padStart = String().padStart(dLeftItem.level * 2, " ");
                lineDivLeft.innerHTML =
                    padStart + dLeftItem.text + (dLeftItem.comma ? "," : "");
                lineDivRight.innerHTML = " ";
                lineDivRight.classList.add(`comparer-line-missing`);
            }
            else if (dRightType === "add" && dRightItem) {
                const padStart = String().padStart(dRightItem.level * 2, " ");
                lineDivLeft.innerHTML = " ";
                lineDivLeft.classList.add(`comparer-line-missing`);
                lineDivRight.innerHTML =
                    padStart + dRightItem.text + (dRightItem.comma ? "," : "");
            }
            iRight++;
            iLeft++;
        }
        lineDivLeft.classList.add(`comparer-line-${dLeftType}`);
        lineDivRight.classList.add(`comparer-line-${dRightType}`);
        divLeft.appendChild(lineDivLeft);
        divRight.appendChild(lineDivRight);
    }
}
