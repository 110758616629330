import { __awaiter } from "tslib";
import { systemRights, } from "@baplie-viewer2/tedivo-api-models";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import EditDrawer from "../../common/EditDrawer";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import SmartTable from "../../common/smartTable/smart-table.element";
import UserTypeEnum from "../../../app/enums/UserTypeEnum";
import createNotification from "../../../app/notifications/createNotification";
import { formatDateInCurrentTimezone } from "@baplie-viewer2/tedivo-pure-helpers";
import globalStore from "../../../app/stores/globalStore";
import goSquared from "../../../app/tracking/goSquared";
import { mergeUsersWithCurrentOrgData } from "../admin/organization-users.component";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
import { z } from "zod";
export class TVDMyOrganizationComponent extends HTMLElement {
    constructor() {
        super();
        this.displayOrganizationUsers = () => {
            globalStore.touchLastUserInteraction();
            const orgId = securityModule.currentOrganizationId;
            if (!orgId)
                return;
            const wrapper = this.wrapper;
            const box = this.mainBox;
            const actionBox = document.createElement("div");
            actionBox.className = "table-action-box";
            removeChildren(box);
            if (!securityModule.planIsReadOnly &&
                securityModule.userHasPermission(systemRights.ORG.CanCreateMoreThanOneUser) &&
                securityModule.userHasPermission(systemRights.USER.CreateUser)) {
                const actionAddUserButton = document.createElement("sl-button");
                actionAddUserButton.innerHTML = getTranslation("general:users.adminCreate");
                actionAddUserButton.variant = "primary";
                actionAddUserButton.addEventListener("click", () => {
                    this.openOrganizationUserEditModal();
                }, false);
                actionBox.appendChild(actionAddUserButton);
            }
            if (!securityModule.planIsReadOnly &&
                securityModule.userHasPermission(systemRights.USER.CanChangeOrganizationName)) {
                const actionEditOrgNameButton = document.createElement("sl-button");
                actionEditOrgNameButton.innerHTML = getTranslation("general:organizations.changeOrganizationDetails");
                actionEditOrgNameButton.variant = "primary";
                actionEditOrgNameButton.addEventListener("click", () => {
                    this.openOrganizationNameEditModal();
                }, false);
                actionBox.appendChild(actionEditOrgNameButton);
            }
            wrapper.setLoading(true);
            Services.organizations
                .getSingleOrganizationAndUsers(orgId)
                .then((resp) => {
                if (resp.data) {
                    box.appendChild(createOrgUsersTable({
                        data: resp.data,
                        actionBox,
                        onEditOrganizationUser: (o) => {
                            this.openOrganizationUserEditModal(Object.assign({}, o));
                        },
                        onDeleteOrganizationUser: this.deleteOrganizationUser,
                        userType: securityModule.userRole,
                        isReadOnly: securityModule.planIsReadOnly,
                    }));
                }
            })
                .catch((e) => {
                this.showError(e);
            })
                .finally(() => {
                wrapper.setLoading(false);
            });
        };
        this.showError = (e, data) => {
            this.dialogError.show(getTranslation(`errors:errorHasOcurred`), getTranslation(typeof e === "string" ? e : e.translationKey, data));
        };
        this.openOrganizationUserEditModal = (prevData) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const orgId = securityModule.currentOrganizationId;
            if (!orgId)
                return;
            const sub = prevData === null || prevData === void 0 ? void 0 : prevData.sub;
            let editForm = null;
            const drawer = this.editDrawer.getEditDrawer({
                title: getTranslation(prevData ? "general:users.adminEdit" : "general:users.adminCreate"),
                showUnits: false,
                readOnlyMode: false,
                onSave: () => __awaiter(this, void 0, void 0, function* () {
                    this.editDrawer.disabled = true;
                    const valid = (yield (editForm === null || editForm === void 0 ? void 0 : editForm.submitFunction())) || false;
                    this.editDrawer.disabled = false;
                    if (valid) {
                        this.displayOrganizationUsers();
                    }
                    return valid;
                }),
            });
            const holder = document.createElement("div-spinner-element");
            holder.setLoading(true);
            drawer.appendChild(holder);
            drawer.show();
            if (sub && prevData) {
                const u = yield Services.accounts.getUserDetailsByAdmin(orgId, sub);
                prevData.mfaOptions = (_a = u.data) === null || _a === void 0 ? void 0 : _a.mfaOptions;
            }
            editForm = createUserEdit(this.editDrawer.submitButton, orgId, prevData, this.showError, securityModule.orgAllowedDomains);
            if (editForm) {
                holder.appendChild(editForm.node);
            }
            holder.setLoading(false);
        });
        this.openOrganizationNameEditModal = () => {
            const editForm = createOrgNameEdit(this.editDrawer.submitButton, this.showError);
            if (editForm) {
                const drawer = this.editDrawer.getEditDrawer({
                    title: getTranslation("general:organizations.changeOrganizationName"),
                    showUnits: false,
                    readOnlyMode: false,
                    onSave: () => __awaiter(this, void 0, void 0, function* () {
                        this.editDrawer.disabled = true;
                        const valid = yield editForm.submitFunction();
                        this.editDrawer.disabled = false;
                        if (valid) {
                            this.displayOrganizationUsers();
                        }
                        return valid;
                    }),
                });
                drawer.appendChild(editForm.node);
                drawer.show();
            }
        };
        this.deleteOrganizationUser = (orgId, dta) => {
            const dialog = this.deleteDialog;
            removeChildren(dialog);
            dialog.label = getTranslation("general:users.delete");
            const htmlContent = document.createElement("div");
            htmlContent.innerHTML = `${getTranslation("general:users.delete")}<br /><br /><strong>${dta.name} ${dta.familyName}</strong>`;
            const deleteBtn = document.createElement("sl-button");
            deleteBtn.slot = "footer";
            deleteBtn.variant = "danger";
            deleteBtn.autofocus = true;
            deleteBtn.tabIndex = 0;
            deleteBtn.innerHTML = getTranslation("general:common.delete");
            deleteBtn.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
                deleteBtn.loading = true;
                deleteBtn.disabled = true;
                yield Services.organizations.adminDeleteOrganizationUser(orgId, dta.sub);
                deleteBtn.loading = false;
                dialog.hide();
                this.displayOrganizationUsers();
            }), false);
            dialog.appendChild(htmlContent);
            dialog.appendChild(deleteBtn);
            this.appendChild(dialog);
            dialog.show();
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.dialogError = new IntegratedDialogError(this);
        this.wrapper = new DivWithSpinner();
        this.mainBox = document.createElement("div");
        this.mainBox.className = "oss-card";
        this.deleteDialog = document.createElement("sl-dialog");
        this.editDrawer = new EditDrawer();
        setAppTitle(getTranslation("menu:organization"));
    }
    connectedCallback() {
        goSquared.trackPage("My Organization");
        goSquared.addEvent("My Organization - Show page");
        const wrapper = this.wrapper, h1 = document.createElement("h1");
        wrapper.appendChild(h1);
        wrapper.appendChild(this.mainBox);
        this.appendChild(this.editDrawer.drawer);
        this.appendChild(wrapper);
        this.displayOrganizationUsers();
        this.i18nCR.addConsumer(topMessageElement.element, "menu:organization", "innerHTML");
        this.i18nCR.addConsumer(h1, "general:users.users", "innerHTML");
        if (securityModule.userHasPermission(systemRights.ORG.AppAdminCrudOrganization)) {
            const btnAdminOrganizations = document.createElement("sl-button");
            btnAdminOrganizations.addEventListener("click", () => {
                router.navigate(routeFns.adminOrganizations());
            });
            btnAdminOrganizations.innerHTML = `Admin - ${getTranslation("general:organizations.organizations")}`;
            btnAdminOrganizations.variant = "primary";
            wrapper.appendChild(btnAdminOrganizations);
        }
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
}
TVDMyOrganizationComponent.observedAttributes = [];
customElements.define("tvd-my-organization-component", TVDMyOrganizationComponent);
function createOrgUsersTable({ data, actionBox, onEditOrganizationUser, onDeleteOrganizationUser, userType, isReadOnly, }) {
    var _a, _b;
    const createSortIcon = (asc) => {
        const icon = document.createElement("sl-icon");
        icon.name = asc ? "sort-down-alt" : "sort-up-alt";
        return icon;
    };
    const organizationId = ((_a = data.organization) === null || _a === void 0 ? void 0 : _a.organizationId) || "";
    const users = mergeUsersWithCurrentOrgData(organizationId, data.users || []);
    const fields = [
        {
            name: "name",
            label: getTranslation("general:common.name"),
        },
        {
            name: "familyName",
            label: getTranslation("general:users.familyName"),
        },
        {
            name: "email",
            label: getTranslation("general:common.email"),
        },
        {
            name: "organizationUserEnabled",
            label: getTranslation("general:common.enabled"),
            mapper: (u) => getTranslation(`general:common.${u.organizationUserEnabled === "1" ? "yes" : "no"}`),
        },
        {
            name: "userStatusType",
            label: getTranslation("general:common.status"),
        },
        {
            name: "organizationUserRole",
            label: getTranslation("general:common.type"),
            mapper: (u) => getTranslation(`enums:UserTypeEnum.${u.organizationUserRole}`),
        },
        {
            name: "userCreateDate",
            label: getTranslation("general:common.createdAt"),
            valueFunction: (dta) => dta.userCreateDate
                ? formatDateInCurrentTimezone(dta.userCreateDate)
                : "",
            className: "centered",
        },
        {
            name: "userLastModifiedDate",
            label: getTranslation("general:common.modifiedAt"),
            valueFunction: (dta) => dta.userLastModifiedDate
                ? formatDateInCurrentTimezone(dta.userLastModifiedDate)
                : "",
            className: "centered",
        },
    ];
    if (userType !== "USER" && isReadOnly === false) {
        fields.push({
            name: "sub",
            label: getTranslation("general:common.edit"),
            type: "icon",
            icon: (dta) => userType === "SUPERADMIN" ||
                (userType === "ADMIN" && dta.planId !== "SUPERADMIN")
                ? "pencil"
                : "",
            notSortable: true,
            onClick: (dta) => {
                onEditOrganizationUser(dta);
            },
        }, {
            name: "sub",
            label: getTranslation("general:common.delete"),
            type: "icon",
            icon: (dta) => `USER#${securityModule.userSub}` !== dta.sub
                ? userType === "SUPERADMIN" ||
                    (userType === "ADMIN" && dta.planId !== "SUPERADMIN")
                    ? "trash"
                    : ""
                : "",
            iconVariant: "danger",
            notSortable: true,
            onClick: (dta) => {
                onDeleteOrganizationUser(organizationId, dta);
            },
        });
    }
    const tableUsers = new SmartTable().initialize({
        className: "tvd-table tvd-table-smart-table",
        data: users || [],
        defaultSort: "name",
        title: ((_b = data.organization) === null || _b === void 0 ? void 0 : _b.name) || "",
        fields,
        settings: {
            sortEnabled: true,
            sortVisible: true,
            filterEnabled: true,
            labelSortBy: getTranslation("general:common.sortBy"),
            labelSearch: getTranslation("general:common.filterBy"),
            createSortIconFn: createSortIcon,
            filterFields: ["name", "familyName", "email"],
            actionBox,
        },
        pkFunction: (dta) => dta.sub,
    });
    return tableUsers;
}
function createUserEdit(submitButton, orgId, prevData, showError, allowedDomains) {
    var _a;
    const holder = document.createElement("div");
    const formValidator = z
        .object({
        email: z
            .string()
            .email()
            .transform((v) => v === null || v === void 0 ? void 0 : v.toLowerCase()),
        name: z.string().min(1),
        familyName: z.string().min(1),
        organizationUserEnabled: z.boolean(),
        organizationUserRole: z.nativeEnum(UserTypeEnum),
        mfa: z.boolean().optional(),
    })
        .refine((data) => {
        if (data.email) {
            const domain = (data.email.split("@")[1] || "").toLowerCase().trim();
            if (allowedDomains.indexOf(domain) < 0)
                return false;
            return true;
        }
        else
            return false;
    }, {
        path: ["email"],
    });
    const hasMfa = (_a = prevData === null || prevData === void 0 ? void 0 : prevData.mfa) !== null && _a !== void 0 ? _a : false;
    const sameUser = (prevData === null || prevData === void 0 ? void 0 : prevData.sub) === securityModule.userSub;
    const formFields = [
        [
            {
                name: "name",
                label: "general:common.name",
                type: "textBox",
                initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.name) || "",
            },
            {
                name: "familyName",
                label: "general:users.familyName",
                type: "textBox",
                initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.familyName) || "",
            },
        ],
        [
            {
                name: "email",
                label: "general:common.email",
                type: "textBox",
                initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.email) || "",
                helpText: getTranslation("general:users.domainsHelpText", {
                    domains: allowedDomains.join(", ") || "-",
                }),
            },
            {
                name: "organizationUserEnabled",
                label: "general:common.enabled",
                type: "checkbox",
                helpText: sameUser
                    ? "general:organizations.userCannotBeDeactivated"
                    : "general:organizations.disableUserHelp",
                initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.organizationUserEnabled) === undefined
                    ? true
                    : (prevData === null || prevData === void 0 ? void 0 : prevData.organizationUserEnabled) === "1",
                padStart: true,
                disabled: sameUser,
            },
            securityModule.userHasPermission(systemRights.USER.CanDisableOthersMfa)
                ? {
                    name: "mfa",
                    label: "auth:mfa.activeMfa",
                    helpText: !hasMfa || sameUser
                        ? "auth:mfa.mfaCanOnlyBeActivatedBySameUser"
                        : "auth:mfa.mfaDeactivateAction",
                    disabled: !hasMfa || sameUser,
                    type: "checkbox",
                    padStart: true,
                    initialValue: hasMfa,
                }
                : undefined,
        ],
        createFieldOptionsForUserTypeEdit(securityModule.userRole || "USER"),
    ];
    const tedivoForm = new TedivoForm({
        fields: formFields,
        onSubmit: () => undefined,
        formValidator,
        submitButton: submitButton,
        autoFocusOnFirstInput: true,
        hiddenData: prevData,
    });
    translateTedivoForm({
        tedivoForm,
        getTranslation: getTranslation,
    });
    holder.appendChild(tedivoForm.form);
    return {
        node: holder,
        tedivoForm,
        submitFunction: submitPassedToEditDrawer,
    };
    function submitPassedToEditDrawer() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            const validResult = tedivoForm.doSubmitForm();
            console.log(validResult);
            if (validResult.success) {
                const values = validResult.data;
                if (prevData === null || prevData === void 0 ? void 0 : prevData.sub) {
                    const data = Object.assign(Object.assign(Object.assign({}, prevData), values), { organizationUserEnabled: values.organizationUserEnabled ? "1" : "0", organizationUserRole: values.organizationUserRole });
                    const resp = yield Services.organizations.adminUpdateOrganizationUser(orgId, data);
                    if (!((_a = resp.data) === null || _a === void 0 ? void 0 : _a.id)) {
                        showError({
                            errorCode: String(resp.statusCode),
                            message: resp.code || "errorModifyingUser",
                            translationKey: resp.message || "errors:errorModifyingUser",
                        }, data);
                        return false;
                    }
                }
                else {
                    const data = Object.assign({}, validResult.data);
                    const resp = yield Services.organizations.adminCreateOrganizationUser(orgId, data);
                    if (!((_b = resp.data) === null || _b === void 0 ? void 0 : _b.id)) {
                        showError({
                            errorCode: String(resp.statusCode),
                            message: resp.code || "errorCreatingUser",
                            translationKey: resp.message || "errors:errorCreatingUser",
                        }, data);
                        return false;
                    }
                    else {
                        if ((_c = resp.data) === null || _c === void 0 ? void 0 : _c.merged) {
                            createNotification(getTranslation("general:users.adminInvited"));
                        }
                    }
                }
                return true;
            }
            else {
                return false;
            }
        });
    }
    function createFieldOptionsForUserTypeEdit(currentUserType) {
        var _a;
        const options = [
            { value: "USER", label: getTranslation(`enums:UserTypeEnum.USER`) },
        ];
        if (currentUserType === "ADMIN" || currentUserType === "SUPERADMIN") {
            options.push({
                value: "ADMIN",
                label: getTranslation(`enums:UserTypeEnum.ADMIN`),
            });
        }
        if (currentUserType === "SUPERADMIN") {
            options.push({
                value: "SUPERADMIN",
                label: getTranslation(`enums:UserTypeEnum.SUPERADMIN`),
            });
        }
        const editUserTypeField = {
            name: "organizationUserRole",
            label: "general:common.type",
            type: "radioButtonList",
            fieldset: true,
            initialValue: (_a = prevData === null || prevData === void 0 ? void 0 : prevData.organizationUserRole) !== null && _a !== void 0 ? _a : "USER",
            options,
            disabled: options.length <= 1 ||
                ((prevData === null || prevData === void 0 ? void 0 : prevData.organizationUserRole) === "SUPERADMIN" &&
                    currentUserType !== "SUPERADMIN"),
        };
        return editUserTypeField;
    }
}
function createOrgNameEdit(submitButton, showError) {
    const holder = document.createElement("div");
    const formFields = [
        {
            name: "name",
            label: "general:common.organizationName",
            type: "textBox",
            initialValue: securityModule.currentOrganizationName || "",
        },
        {
            name: "mfaForAll",
            label: "auth:mfa.mfaForAllUsers",
            helpText: "auth:mfa.mfaForAllUsersInfo",
            type: "checkbox",
            initialValue: securityModule.organizationMfaForAllUsers,
        },
    ];
    const formValidator = z.object({
        name: z.string().min(3),
        mfaForAll: z.boolean().optional(),
    });
    const tedivoForm = new TedivoForm({
        fields: formFields,
        onSubmit: () => undefined,
        formValidator,
        submitButton: submitButton,
        autoFocusOnFirstInput: true,
    });
    translateTedivoForm({
        tedivoForm,
        getTranslation: getTranslation,
    });
    holder.appendChild(tedivoForm.form);
    return {
        node: holder,
        tedivoForm,
        submitFunction: submitPassedToEditDrawer,
    };
    function submitPassedToEditDrawer() {
        return __awaiter(this, void 0, void 0, function* () {
            const validResult = tedivoForm.doSubmitForm();
            if (validResult.success) {
                const resp = yield Services.accounts.changeOrganizationSelfDetails(validResult.data.name, validResult.data.mfaForAll || false);
                if (resp.statusCode !== 200) {
                    showError({
                        errorCode: String(resp.statusCode),
                        message: resp.code || "errorModifyingOrg",
                        translationKey: resp.message || "errors:errorModifyingOrg",
                    });
                    return false;
                }
                securityModule.updateOrganizationDetails(validResult.data.name, validResult.data.mfaForAll || false);
                return true;
            }
            else {
                return false;
            }
        });
    }
}
