import { __awaiter } from "tslib";
import { systemRights, } from "@baplie-viewer2/tedivo-api-models";
import awsCognito from "./awsCognito";
import Services from "../services";
import { errorTracking } from "../tracking/errorTracking";
import { setPreferencesKeyAndValue } from "@baplie-viewer2/tedivo-preferences";
export class SecurityModule extends EventTarget {
    constructor() {
        super();
        this.changePassword = (oldPassword, newPassword) => awsCognito.changePassword(this.userSub, oldPassword, newPassword);
        this.xhrRequestFired = () => {
            awsCognito.initiateRefreshToken();
        };
        this.getOrganizationUsersBySub = (orgId) => __awaiter(this, void 0, void 0, function* () {
            if (!this.isLoggedIn)
                return undefined;
            if (awsCognito.orgUsersBySub) {
                return awsCognito.orgUsersBySub;
            }
            else {
                const resp = yield Services.accounts.getMyOrganizationUsers(orgId);
                awsCognito.orgUsersBySub = resp.data;
                return resp.data;
            }
        });
        this.associateMfaDevice = awsCognito.associateMfaDevice;
        this.verifyAndFinishMfaDeviceAssociation = awsCognito.verifyAndFinishMfaDeviceAssociation;
        awsCognito.addEventListener("authenticationChanged", (ev) => {
            const { isLoggedIn } = ev.detail;
            window.setTimeout(() => {
                const ev = isLoggedIn ? "loggedIn" : "loggedOut";
                this.dispatchEvent(new CustomEvent(ev));
            }, 0);
        });
        awsCognito.addEventListener("userDataRetrieved", () => {
            var _a;
            this.passInfoToTrackers(((_a = awsCognito.userEditableDetails) === null || _a === void 0 ? void 0 : _a.email) || "");
            this.dispatchEvent(new CustomEvent("userDataRetrieved"));
        });
        awsCognito.addEventListener("sessionTimedOut", () => {
            this.dispatchEvent(new CustomEvent("sessionTimedOut"));
        });
    }
    get isLoggedIn() {
        return awsCognito.isLoggedIn;
    }
    get idToken() {
        return awsCognito.idToken;
    }
    get initials() {
        var _a, _b;
        if (!this.isLoggedIn)
            return "-";
        const userData = awsCognito.userEditableDetails;
        return `${((_a = userData === null || userData === void 0 ? void 0 : userData.name) === null || _a === void 0 ? void 0 : _a[0]) || "?"}${((_b = userData === null || userData === void 0 ? void 0 : userData.familyName) === null || _b === void 0 ? void 0 : _b[0]) || "?"}`;
    }
    /** Cognito Username (sub) */
    get userSub() {
        if (!this.isLoggedIn)
            return "";
        return awsCognito.userSub || "";
    }
    /** The first name of the logged in user */
    get name() {
        if (!this.isLoggedIn)
            return "";
        return awsCognito.userEditableDetails.name || "";
    }
    /** The family name of the logged in user */
    get familyName() {
        if (!this.isLoggedIn)
            return "";
        return awsCognito.userEditableDetails.familyName || "";
    }
    /** The display name of the logged in user */
    get displayName() {
        if (!this.isLoggedIn)
            return "";
        const userDetails = awsCognito.userEditableDetails;
        return `${(userDetails === null || userDetails === void 0 ? void 0 : userDetails.name) || ""} ${(userDetails === null || userDetails === void 0 ? void 0 : userDetails.familyName) || ""}`;
    }
    get email() {
        if (!this.isLoggedIn)
            return "";
        return awsCognito.userEditableDetails.email || "";
    }
    get userRole() {
        var _a;
        if (!this.isLoggedIn)
            return undefined;
        return ((_a = awsCognito.currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.role) || "USER";
    }
    get currentOrganizationId() {
        var _a;
        if (!this.isLoggedIn)
            return "";
        return ((_a = awsCognito.currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.orgId) || "";
    }
    get currentOrganizationName() {
        var _a;
        if (!this.isLoggedIn)
            return "";
        return ((_a = awsCognito.currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.name) || "";
    }
    get currentOrganizationPlanId() {
        var _a;
        if (!this.isLoggedIn)
            return "";
        return ((_a = awsCognito.currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.planId) || "FREE";
    }
    get organizationMfaForAllUsers() {
        return awsCognito.orgMfaForAllUsers;
    }
    get isLoginInfoComplete() {
        return awsCognito.isLoginInfoComplete;
    }
    get isMfaEnabled() {
        return awsCognito.isMfaEnabled;
    }
    get orgAllowedDomains() {
        return awsCognito.orgAllowedDomains;
    }
    get planIsExpired() {
        var _a;
        if (((_a = awsCognito.currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.expired) === "1")
            return true;
        return false;
    }
    get planExpirationDate() {
        var _a;
        const expDate = (_a = awsCognito.currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.expDate;
        return expDate ? new Date(expDate) : undefined;
    }
    get planIsReadOnly() {
        return this.planIsExpired;
    }
    get sessionIsExpired() {
        const sessionExpires = awsCognito.sessionExpires;
        if (!sessionExpires)
            return true;
        return sessionExpires < new Date();
    }
    getBeaconMetadata() {
        return {
            date: new Date(),
            userSub: awsCognito.userSub || "",
            organizationId: this.currentOrganizationId,
        };
    }
    passInfoToTrackers(email) {
        if (this.isLoggedIn) {
            errorTracking.identify(this.userSub, email, this.displayName, this.currentOrganizationName);
        }
        else {
            // We want to know who was using it. So we don't delete info when logged out.
        }
    }
    authenticate(email, password) {
        return __awaiter(this, void 0, void 0, function* () {
            const emailClean = email.trim().toLowerCase();
            const passwordClean = password.trim();
            const resp = yield awsCognito.authenticateUser(emailClean, passwordClean);
            setPreferencesKeyAndValue("currentUsername", this.isLoggedIn ? emailClean : "");
            return resp;
        });
    }
    setNewPassword(email, password, acceptsMarketingMails) {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield awsCognito.setNewPassword(email, password);
            setPreferencesKeyAndValue("currentUsername", awsCognito.isLoggedIn ? email : "");
            awsCognito.tempAcceptsMarketingMails = acceptsMarketingMails;
            return resp;
        });
    }
    signOut() {
        return __awaiter(this, void 0, void 0, function* () {
            setPreferencesKeyAndValue("currentUsername", "");
            yield awsCognito.signOut();
            this.passInfoToTrackers("");
        });
    }
    clearSession() {
        awsCognito.clearSession();
    }
    checkIfUserIsLoggedIn() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield awsCognito.checkIfUserIsLoggedIn();
        });
    }
    userHasPermission(right) {
        if (right === systemRights.ANONYMOUS)
            return true;
        if (right === systemRights.NOT_LOGGED_IN)
            return !this.isLoggedIn;
        return this.userHasSystemRight(right);
    }
    userHasSystemRight(right) {
        var _a;
        const rights = (_a = awsCognito.currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.rights;
        if (!rights)
            return false;
        return rights.indexOf(right) >= 0;
    }
    get userIsTedivoAdmin() {
        return this.userHasSystemRight(systemRights.ORG.IsTedivoAdmin);
    }
    updateUserDetails(name, familyName, email, marketingMails) {
        awsCognito.updateUserDetails(name, familyName, email, marketingMails);
    }
    updateOrganizationDetails(organizationName, mfaForAllUsers) {
        awsCognito.updateOrganizationName(organizationName);
        awsCognito.orgMfaForAllUsers = mfaForAllUsers;
    }
}
const securityModule = new SecurityModule();
if (process.env.NX_PUBLIC_STAGE === "dev")
    window.securityModule = securityModule;
export default securityModule;
