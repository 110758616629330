import Bugsnag from "@bugsnag/js";
export const errorTracking = {
    start(bugsnagId, stage) {
        var _a;
        const appVersion = `v1.0.${(_a = process.env.NX_PUBLIC_BUILD_NUMBER) !== null && _a !== void 0 ? _a : "Beta"}`;
        Bugsnag.start({
            apiKey: bugsnagId || "",
            releaseStage: stage || "unknown",
            appVersion,
            maxBreadcrumbs: 100,
        });
        console.info("TEDIVO Vessel Designer ", appVersion);
        if (process.env.NX_PUBLIC_STAGE === "dev")
            window.Bugsnag = Bugsnag;
    },
    identify(userSub, email, displayName, organizationName) {
        if (!Bugsnag.isStarted())
            return;
        Bugsnag.setUser(userSub, email, `${displayName} / ${organizationName}`);
    },
    leaveBreadcrumb(message, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata, type) {
        if (!Bugsnag.isStarted())
            return;
        Bugsnag.leaveBreadcrumb(message, metadata, type);
    },
};
