import {
  Dragger,
  IDraggerDetails,
} from "@baplie-viewer2/tedivo-bay-grid-interactive";
import {
  I20LcgsByBay,
  IGetBayLcgVcgAndPairingsResult,
  IRowPattern,
  TCG_IN_MMM,
  createSimpleDeckView,
  validateLidData,
} from "@baplie-viewer2/tedivo-bay-grid-core";
import { IBayLevelData, ILidData, ISizeSummary } from "open-vessel-definition";
import {
  TedivoForm,
  createInputWithUnits,
  onCheckboxKeyPressed,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";
import createFormFields, {
  CreateLidFormFieldsValidator,
  ILidDataStore,
} from "./createLidsFormFields";
import {
  numberOrUndefined,
  pad2,
  pad3,
  roundDec,
  sortNumericAsc,
} from "@baplie-viewer2/tedivo-pure-helpers";

import EditDrawer from "../../../../../components/common/EditDrawer";
import FieldsValuesStore from "../../../../../app/stores/FieldsValuesStore";
import IntegratedDialogError from "packages/oss-editor/src/components/common/IntegratedDialogError";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import SlInput from "@shoelace-style/shoelace/dist/components/input/input";
import { getTranslation } from "../../../../../app/i18/i18tn";
import globalUnits from "../../../../../app/units/globalUnits";
import goSquared from "../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { sortRowsArray } from "@baplie-viewer2/tedivo-bay-grid-core";

const SVG_G_NAME = "editLidsSvgG";

export function createLidsEdit(
  sizeSummary: ISizeSummary,
  bls: Array<IBayLevelData>,
  lidsData: ILidData[],
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult,
  usingAModal: (isOpened: boolean) => void,
  editDrawer: EditDrawer,
): { node: HTMLElement; submitFunction: () => boolean } {
  goSquared.addEvent("Edit-OVD - Edit Lids");

  const holder = document.createElement("div");
  const editingArea = document.createElement("section");
  editingArea.className = "editing-area";

  const dataStore = new FieldsValuesStore();

  const currentSvg: { current: ISvgDetails | undefined } = {
    current: undefined,
  };

  let slotsDeckPositions: IDeckSlotsPositions;

  // This is the DIV where the Deck SVG with Lids will be
  const svgWrapper = document.createElement("div");
  svgWrapper.className = "lids-edit-mainHolder";

  createEditingFields(
    sizeSummary,
    lidsData,
    editingArea,
    dataStore,
    usingAModal,
  );

  setTimeout(() => {
    const drawing = drawSvgWithLids(
      sizeSummary,
      lidsData,
      lcgVcgTcgAndPairings,
    );

    if (drawing) {
      currentSvg.current = drawing;

      const { deckViewSvg, xPos, zPos } = currentSvg.current;

      slotsDeckPositions = createSlotsDeckPositions(
        lcgVcgTcgAndPairings,
        drawing.lcgsBy20Bay,
        xPos,
        zPos,
      );

      flipDeckSvg(deckViewSvg);
      svgWrapper.appendChild(deckViewSvg);

      const dragger = new Dragger(svgWrapper, onDraggerEvent);
      editingArea.appendChild(dragger.node);

      // When data changes, remove and re-create the SVG
      dataStore.onChange = () => {
        if (currentSvg.current?.deckViewSvg)
          currentSvg.current.deckViewSvg.remove(); // Remove previous SVG

        const newDrawing = drawSvgWithLids(
          sizeSummary,
          getCurrentLidData(),
          lcgVcgTcgAndPairings,
        );

        if (newDrawing) {
          currentSvg.current = newDrawing;
          flipDeckSvg(newDrawing.deckViewSvg);
          svgWrapper.appendChild(newDrawing.deckViewSvg);
        }

        editDrawer.dataIsDirty = true;
      };
    }
  }, 1000);

  dataStore.updateConsumers();

  holder.appendChild(editingArea);

  const submitFunction = () => {
    goSquared.addEvent("Edit-OVD - Edit Lids - Save");
    ovdJsonStore.setLids(getCurrentLidData());

    return true;
  };

  return { node: holder, submitFunction };

  function getCurrentLidData() {
    const baseKeys = dataStore
      .getAllKeys()
      .map((s) => s.substring(0, s.indexOf(".")))
      .filter((s, idx, arr) => arr.indexOf(s) === idx)
      .filter((s) => rowsFactory.deletedLids.indexOf(Number(s)) < 0)
      .sort(sortNumericAsc);

    const newLids = baseKeys.map((baseKey) =>
      convertLidDataStoreToILidData(
        dataStore.getValuesOfParent<ILidDataStore>(baseKey),
      ),
    );

    return newLids;
  }

  function onDraggerEvent(event: IDraggerDetails): void {
    console.log(event);
    if (currentSvg.current === undefined) return;
    if (event.hasDragged) onDrag(event);
    if (event.isDoubleClick) onDoubleClick(event);
  }

  function onDoubleClick(event: IDraggerDetails): void {
    const target = event.originalEvent?.target as Element;
    if (target && target.getAttribute("data-idx")) {
      const idx = target.getAttribute("data-idx");
      if (idx)
        createLidModal(
          rowsFactory.dataStore.getValueAsNumber(`${idx}.startIsoBay`) || 0,
          rowsFactory.dataStore.getValueAsNumber(`${idx}.endIsoBay`) || 0,
          rowsFactory.dataStore.getValueAsNumber(`${idx}.portIsoRow`) || 0,
          rowsFactory.dataStore.getValueAsNumber(`${idx}.starboardIsoRow`) || 0,
          String(rowsFactory.dataStore.getValue(`${idx}.label`)),
          rowsFactory.dataStore.getValueAsNumber(`${idx}.weight`) || 0,
          rowsFactory.dataStore.getValueAsNumber(`${idx}.overlapPort`) || 0,
          rowsFactory.dataStore.getValueAsNumber(`${idx}.overlapStarboard`) ||
            0,
          Number(idx),
        );
    }
  }

  function onDrag(event: IDraggerDetails): void {
    if (currentSvg.current === undefined) return;

    const { deckViewSvg, zRange, xRange } = currentSvg.current;

    const responsiveRatio =
      deckViewSvg.getBoundingClientRect().width / (zRange || 1);

    const slotsSelected = Object.keys(slotsDeckPositions).filter((k) => {
      const { maxZ, minZ, maxX, minX } = slotsDeckPositions[k];

      const box = {
        x0: minZ * responsiveRatio,
        x1: maxZ * responsiveRatio,
        y0: (xRange - maxX) * responsiveRatio,
        y1: (xRange - minX) * responsiveRatio,
      };

      return (
        event.startX <= box.x1 &&
        event.endX >= box.x0 &&
        event.startY <= box.y1 &&
        event.endY >= box.y0
      );
    });

    console.log(slotsDeckPositions, slotsSelected);

    if (slotsSelected.length === 0) {
      const modal = new IntegratedDialogError(holder, true);
      modal.show(
        getTranslation("general:common.attention"),
        getTranslation("view:lids.noContainersBelow"),
      );
      return;
    }

    const tiersRowsSelected: { tiers: number[]; rows: number[] } = {
      tiers: [],
      rows: [],
    };

    slotsSelected.forEach((key) => {
      const [tier, row] = key.split("-");
      tiersRowsSelected.tiers.push(Number(tier));
      tiersRowsSelected.rows.push(Number(row));
    });

    const startIsoBay = Math.min.apply(null, tiersRowsSelected.tiers);
    const endIsoBay = Math.max.apply(null, tiersRowsSelected.tiers);
    const uniqueRows = tiersRowsSelected.rows
      .filter((v, idx, arr) => arr.indexOf(v) === idx)
      .map((v) => pad2(v))
      .sort(sortRowsArray);

    createLidModal(
      startIsoBay,
      endIsoBay,
      Number(uniqueRows[0]),
      Number(uniqueRows[uniqueRows.length - 1]),
      `N${pad3(rowsFactory.lidsCounter)}`,
      0,
      0,
      0,
      -1,
    );
  }

  function createLidModal(
    startIsoBay: number,
    endIsoBay: number,
    portIsoRow: number,
    starboardIsoRow: number,
    label: string,
    weight: number,
    overlapPort: number,
    overlapStarboard: number,
    arrayPosition: number,
  ) {
    const modal = document.createElement("sl-dialog");
    modal.label = getTranslation(
      `view:lids.${arrayPosition < 0 ? "new" : "edit"}`,
    );
    modal.setAttribute("style", "--width: 50vw");

    modal.addEventListener(
      "sl-after-hide",
      (ev) => {
        if (ev.target === modal) {
          usingAModal(false);
          modal.remove();
        }
      },
      false,
    );

    // Delete button
    if (arrayPosition >= 0) {
      const btnDeleteLid = document.createElement("sl-button");
      btnDeleteLid.innerHTML = getTranslation("general:common.delete");
      btnDeleteLid.variant = "danger";
      btnDeleteLid.slot = "footer";
      modal.appendChild(btnDeleteLid);
      const btnDeleteLidIcon = document.createElement("sl-icon");
      btnDeleteLidIcon.name = "trash";
      btnDeleteLidIcon.slot = "prefix";
      btnDeleteLid.appendChild(btnDeleteLidIcon);
      btnDeleteLid.addEventListener(
        "click",
        () => {
          modal.hide();
          rowsFactory.deleteLid(arrayPosition);
        },
        false,
      );
    }

    // Save button
    const btnSave = document.createElement("sl-button");
    btnSave.innerHTML = getTranslation("view:lids.save");
    btnSave.variant = "primary";
    btnSave.slot = "footer";
    modal.appendChild(btnSave);
    btnSave.addEventListener("click", saveLidWithFormData, false);

    const tedivoForm = new TedivoForm<ILidDataStore>({
      fields: createFormFields(
        label,
        startIsoBay,
        endIsoBay,
        portIsoRow,
        starboardIsoRow,
        weight,
        overlapPort,
        overlapStarboard,
      ),
      onSubmit: () => null,
      formValidator: CreateLidFormFieldsValidator,
      submitButton: btnSave,
    });

    translateTedivoForm<ILidDataStore>({
      tedivoForm,
      getTranslation,
    });

    modal.appendChild(tedivoForm.form);
    holder.appendChild(modal);

    usingAModal(true);
    modal.show();

    function saveLidWithFormData() {
      const validResult = tedivoForm.doSubmitForm();

      if (!validResult.success) return false;

      const values = tedivoForm.getValues();
      const lidData: ILidData = {
        label: values.label || "",
        startIsoBay: pad3(values.startIsoBay),
        endIsoBay: pad3(values.endIsoBay),
        portIsoRow: pad2(values.portIsoRow),
        starboardIsoRow: pad2(values.starboardIsoRow),
        weight: values.weight,
        overlapPort: values.overlapPort ? 1 : 0,
        overlapStarboard: values.overlapStarboard ? 1 : 0,
      };

      if (arrayPosition < 0) {
        rowsFactory.drawEditingLid(lidData, rowsFactory.lidsCounter);
        rowsFactory.lidsCounter += 1;
      } else {
        const baseKey = String(arrayPosition);
        rowsFactory.dataStore
          .setValue(`${baseKey}.label`, lidData.label)
          .setValue(`${baseKey}.startIsoBay`, Number(lidData.startIsoBay))
          .setValue(`${baseKey}.endIsoBay`, Number(lidData.endIsoBay))
          .setValue(`${baseKey}.portIsoRow`, Number(lidData.portIsoRow))
          .setValue(
            `${baseKey}.starboardIsoRow`,
            Number(lidData.starboardIsoRow),
          )
          .setValue(`${baseKey}.weight`, lidData.weight)
          .setValue(`${baseKey}.overlapPort`, lidData.overlapPort)
          .setValue(`${baseKey}.overlapStarboard`, lidData.overlapStarboard)
          .updateConsumers();
      }

      if (dataStore.onChange) dataStore.onChange();

      modal.hide();
      return true;
    }
  }

  /**
   * Generates an object with all the positions of the slots-containers over the deck
   */
  function createSlotsDeckPositions(
    lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult,
    lcgsBy20Bay: I20LcgsByBay,
    xPos: (lcg: number, d?: number | undefined) => number,
    zPos: (tcg: number, d?: number | undefined) => number,
  ): IDeckSlotsPositions {
    const slotsPositionsInMm: IDeckSlotsPositions = {};

    const { bayLevelPositionsBelow } = lcgVcgTcgAndPairings;

    bayLevelPositionsBelow.forEach((blp) => {
      const rows = Object.keys(blp.rows).sort() as IRowPattern[];
      rows.forEach((rowName) => {
        const strName = `${blp.bayIsoNumber}-${rowName}`;
        const rowInfo = blp.rows[rowName];

        slotsPositionsInMm[strName] = {
          minX: xPos(lcgsBy20Bay[blp.bayIsoNumber].aftLcg),
          maxX: xPos(lcgsBy20Bay[blp.bayIsoNumber].foreLcg),
          minZ: roundDec(zPos((rowInfo.tcg || 0) - TCG_IN_MMM * 0.5), 0),
          maxZ: roundDec(zPos((rowInfo.tcg || 0) + TCG_IN_MMM * 0.5), 0),
        };
      });
    });

    return slotsPositionsInMm;
  }
}

function createEditingFields(
  sizeSummary: ISizeSummary,
  lidsData: ILidData[],
  editingArea: HTMLElement,
  dataStore: FieldsValuesStore,
  usingAModal: (a: boolean) => void,
) {
  // 1. Lids
  const divTable = document.createElement("div");

  const table = document.createElement("table");
  const tHead = document.createElement("thead");
  const tBody = document.createElement("tbody");
  table.className = "tvd-table tvd-table-sticky";

  rowsFactory.init(dataStore, sizeSummary, tBody, editingArea, usingAModal);
  rowsFactory.lidsCounter = lidsData.length;

  const cols = [
    "general:common.label",
    "view:lids.portIsoRow",
    "view:lids.starboardIsoRow",
    "view:lids.startIsoBay",
    "view:lids.endIsoBay",
    "view:weight",
    "view:lids.overlapPort",
    "view:lids.overlapStarboard",
    "general:common._EMPTY_",
  ];

  // 1.1 Headers
  const tr = document.createElement("tr");
  cols.forEach((colLabel) => {
    const td = document.createElement("th");
    const sp = document.createElement("span");
    sp.innerHTML = getTranslation(colLabel);
    sp.className = colLabel.replace(/[.|:]/gm, "-");
    td.appendChild(sp);
    tr.appendChild(td);
  });
  tHead.appendChild(tr);

  lidsData.forEach((lid, idx) => {
    rowsFactory.drawEditingLid(lid, idx);
  });

  table.appendChild(tHead);
  table.appendChild(tBody);

  divTable.appendChild(table);

  const addNewLidBtn = document.createElement("sl-button");
  addNewLidBtn.innerHTML = getTranslation("view:lids.new");
  addNewLidBtn.tabIndex = 0;
  addNewLidBtn.variant = "primary";
  addNewLidBtn.outline = true;
  addNewLidBtn.onclick = function () {
    rowsFactory.drawEditingLid(
      { label: `N${pad3(rowsFactory.lidsCounter)}` } as ILidData,
      rowsFactory.lidsCounter,
    );
    rowsFactory.lidsCounter += 1;
  };

  divTable.appendChild(addNewLidBtn);

  editingArea.appendChild(divTable);
}

const rowsFactory = {
  deleteConfirmationDialog: {} as SlDialog,
  deletedLids: [] as number[],
  dataStore: {} as FieldsValuesStore,
  sizeSummary: {} as ISizeSummary,
  tBody: {} as HTMLElement,
  lidsCounter: 0,
  hoveredNode: undefined as HTMLElement | SVGElement | undefined,
  highlightedRows: [] as Element[],
  usingAModal: undefined as ((a: boolean) => void) | undefined,

  init(
    dataStore: FieldsValuesStore,
    sizeSummary: ISizeSummary,
    tBody: HTMLElement,
    editingArea: HTMLElement,
    usingAModal: (a: boolean) => void,
  ) {
    const me = rowsFactory;

    me.deleteConfirmationDialog = document.createElement("sl-dialog");
    me.deleteConfirmationDialog.classList.add("higher-modal-z-index");
    editingArea.appendChild(me.deleteConfirmationDialog);

    me.deletedLids = [];
    me.dataStore = dataStore;
    me.sizeSummary = sizeSummary;
    me.tBody = tBody;
    me.usingAModal = usingAModal;

    editingArea.addEventListener("mousemove", me.onLidOrRowHover, false);
  },

  deleteLid(idx: number) {
    const me = rowsFactory;
    const confirmDelete = document.createElement("sl-button");
    confirmDelete.innerHTML = getTranslation("general:common.delete");
    confirmDelete.slot = "footer";
    confirmDelete.tabIndex = 0;
    confirmDelete.variant = "danger";

    removeChildren(me.deleteConfirmationDialog);
    me.deleteConfirmationDialog.label = getTranslation("view:lids.deleteLid");

    const lidData = me.dataStore.getValuesOfParent<ILidDataStore>(String(idx));
    if (lidData) me.deleteConfirmationDialog.innerHTML = lidData.label || "";

    me.deleteConfirmationDialog.appendChild(confirmDelete);

    confirmDelete.onclick = function () {
      me.usingAModal?.(false);
      me.deleteConfirmationDialog.hide();

      const tr = document.getElementById(`lid-table-row-${idx}`);

      if (tr === null) return;

      tr.classList.add("deleted-tr");
      me.deletedLids.push(Number(idx));

      window.setTimeout(() => {
        tr.style.display = "none";
      }, 1500);

      if (me.dataStore.onChange) me.dataStore.onChange();
    };

    confirmDelete.onkeydown = function (ev: KeyboardEvent) {
      if (ev.key === " ") {
        confirmDelete.click();
      }
    };

    me.deleteConfirmationDialog.show();
    me.usingAModal?.(true);
  },

  drawEditingLid(lid: ILidData, idx: number) {
    const me = rowsFactory;

    function getIdxFromParent(el: HTMLElement): number {
      const tr = findParentTr(el);
      return Number(tr?.dataset.idx || -1);
    }

    function onDeleteClicked(ev: Event) {
      const t = ev.target as HTMLElement;
      me.deleteLid(getIdxFromParent(t));
    }

    function onDeleteKeyPressed(ev: KeyboardEvent): void {
      const t = ev.target as SlButton;
      if (ev.key === " ") {
        ev.preventDefault();
        me.deleteLid(getIdxFromParent(t));
      }
    }

    const baseKey = String(idx);
    const tr = document.createElement("tr");
    tr.setAttribute("id", `lid-table-row-${idx}`);
    tr.dataset.idx = String(idx);

    // 1.1.1 label
    const td0 = document.createElement("td");
    const inpLabel = document.createElement("sl-input");
    inpLabel.name = `${baseKey}.label`;
    inpLabel.type = "text";
    inpLabel.maxlength = 12;
    inpLabel.required = true;
    inpLabel.size = "small";
    inpLabel.tabIndex = 0;
    inpLabel.value = String(
      me.dataStore.getValue(`${baseKey}.label`) || lid.label,
    );
    me.dataStore.registerInputField(inpLabel);
    td0.appendChild(inpLabel);
    tr.appendChild(td0);

    // 1.1.2 portIsoRow
    const td2 = document.createElement("td");
    const inp2 = createInputWithUnits({
      name: `${baseKey}.portIsoRow`,
      value:
        me.dataStore.getValueAsNumber(`${baseKey}.portIsoRow`) ||
        numberOrUndefined(lid.portIsoRow),
    });
    inp2.min = me.sizeSummary.centerLineRow ? 0 : 1;
    inp2.max = numberOrUndefined(me.sizeSummary.maxRow) || 30;
    me.dataStore.registerInputField(inp2);
    td2.appendChild(inp2);
    tr.appendChild(td2);

    // 1.1.3 starboardIsoRow
    const td3 = document.createElement("td");
    const inp3 = createInputWithUnits({
      name: `${baseKey}.starboardIsoRow`,
      value:
        me.dataStore.getValueAsNumber(`${baseKey}.starboardIsoRow`) ||
        numberOrUndefined(lid.starboardIsoRow),
    });
    inp3.min = me.sizeSummary.centerLineRow ? 0 : 1;
    inp3.max = numberOrUndefined(me.sizeSummary.maxRow) || 30;
    me.dataStore.registerInputField(inp3);
    td3.appendChild(inp3);
    tr.appendChild(td3);

    // 1.1.4 startIsoBay
    const td4 = document.createElement("td");
    const inp4 = createInputWithUnits({
      name: `${baseKey}.startIsoBay`,
      value:
        me.dataStore.getValueAsNumber(`${baseKey}.startIsoBay`) ||
        numberOrUndefined(lid.startIsoBay),
    });
    inp4.min = 1;
    inp4.max = numberOrUndefined(me.sizeSummary.isoBays) || 100;
    inp4.addEventListener("sl-change", mustBeOdd, false);
    me.dataStore.registerInputField(inp4);
    td4.appendChild(inp4);
    tr.appendChild(td4);

    // 1.1.5 endIsoBay
    const td5 = document.createElement("td");
    const inp5 = createInputWithUnits({
      name: `${baseKey}.endIsoBay`,
      value:
        me.dataStore.getValueAsNumber(`${baseKey}.endIsoBay`) ||
        numberOrUndefined(lid.endIsoBay),
    });
    inp5.min = 1;
    inp5.max = numberOrUndefined(me.sizeSummary.isoBays) || 100;
    inp5.addEventListener("sl-change", mustBeOdd, false);
    me.dataStore.registerInputField(inp5);
    td5.appendChild(inp5);
    tr.appendChild(td5);

    // 1.1.5 weight
    const tdW = document.createElement("td");
    const inpW = createInputWithUnits({
      name: `${baseKey}.weight`,
      value: me.dataStore.getValueAsNumber(`${baseKey}.weight`) || lid.weight,
      converter: globalUnits.massUnits,
    });
    me.dataStore.registerInputField(inpW);
    me.dataStore.registerDataConsumer(
      inpW,
      "placeholder",
      `${baseKey}.common.maxHeight`,
    );
    tdW.appendChild(inpW);
    tr.appendChild(tdW);

    // 1.1.7 overlapPort
    const td6 = document.createElement("td");
    td6.className = "centered";
    const inp6 = document.createElement("sl-switch");
    inp6.tabIndex = 0;
    inp6.addEventListener("keydown", onCheckboxKeyPressed, false);
    inp6.name = `${baseKey}.overlapPort`;
    inp6.checked =
      !!me.dataStore.getValue(`${baseKey}.overlapPort`) || !!lid.overlapPort;
    me.dataStore.registerCheckbox(inp6);
    td6.appendChild(inp6);
    tr.appendChild(td6);

    // 1.1.8 overlapStarboard
    const td7 = document.createElement("td");
    td7.className = "centered";
    const inp7 = document.createElement("sl-switch");
    inp7.tabIndex = 0;
    inp7.name = `${baseKey}.overlapStarboard`;
    inp7.tabIndex = 0;
    inp7.addEventListener("keydown", onCheckboxKeyPressed, false);

    inp7.checked =
      !!me.dataStore.getValue(`${baseKey}.overlapStarboard`) ||
      !!lid.overlapStarboard;
    me.dataStore.registerCheckbox(inp7);
    td7.appendChild(inp7);
    tr.appendChild(td7);

    const td8 = document.createElement("td");
    td8.className = "centered";
    const btnDelete = document.createElement("sl-button");
    const icon = document.createElement("sl-icon");
    btnDelete.size = "small";
    btnDelete.variant = "danger";
    btnDelete.outline = true;
    icon.name = "trash";
    btnDelete.tabIndex = 0;
    btnDelete.appendChild(icon);
    btnDelete.addEventListener("click", onDeleteClicked, false);
    btnDelete.addEventListener("keydown", onDeleteKeyPressed, false);
    td8.appendChild(btnDelete);
    tr.appendChild(td8);

    me.tBody.appendChild(tr);
  },

  onLidOrRowHover(e: Event) {
    const HTML_NODES_ALLOWED = [
      "TD",
      "SL-INPUT",
      "TF-INPUT-UNITS",
      "SL-SWITCH",
    ];
    const me = rowsFactory;
    const target = e.target as HTMLElement | SVGElement;

    // Don't pass if the same node
    if (me.hoveredNode === target) return;
    me.hoveredNode = target;

    // Un-highlight nodes
    me.highlightedRows.forEach((node) => {
      node.classList.remove("row-highlighted");
    });

    // Check it's TD or PATH
    const nodeName = target.nodeName;

    if (HTML_NODES_ALLOWED.indexOf(nodeName) < 0 && nodeName !== "path") return;

    let idx: string | undefined = undefined;

    // Find IDX
    if (nodeName === "path") {
      idx = target.dataset.idx;
    } else {
      const tr = findParentTr(target as HTMLElement);
      idx = tr ? tr.dataset.idx : undefined;
    }

    if (idx === undefined) return;

    const nodesWithIdx = document.querySelectorAll(
      `.editing-area tr[data-idx='${idx}'], .editing-area path[data-idx='${idx}']`,
    );

    nodesWithIdx.forEach((node) => {
      node.classList.add("row-highlighted");
    });

    me.highlightedRows = Array.from(nodesWithIdx);
  },
};

function convertLidDataStoreToILidData(lidDStore: ILidDataStore): ILidData {
  return {
    label: lidDStore.label || "",
    portIsoRow: pad2(lidDStore.portIsoRow),
    starboardIsoRow: pad2(lidDStore.starboardIsoRow),
    startIsoBay: pad3(lidDStore.startIsoBay),
    endIsoBay: pad3(lidDStore.endIsoBay),
    overlapPort: lidDStore.overlapPort ? 1 : undefined,
    overlapStarboard: lidDStore.overlapStarboard ? 1 : undefined,
    weight: lidDStore.weight,
  };
}

function drawSvgWithLids(
  sizeSummary: ISizeSummary,
  lidsData: ILidData[],
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult,
): ISvgDetails | null {
  const root = getComputedStyle(document.body);
  const lidsDataValidated = validateLidData(lidsData);

  try {
    const {
      deckViewSvg,
      missingImportantXcgs,
      xPos,
      addX,
      xRange,
      zPos,
      zRange,
      lcgsBy20Bay,
    } = createSimpleDeckView({
      sizeSummary,
      lidData: lidsDataValidated,
      lcgVcgTcgAndPairings,
      symbolsOptions: {
        strokeWidth: 1,
        strokeColor: root.getPropertyValue("--sl-color-neutral-200"),
        fontColor: root.getPropertyValue("--sl-color-neutral-700"),
        fillColor: root.getPropertyValue("--sl-color-neutral-50"),
        shipStrokeColor: root.getPropertyValue("--sl-color-primary-300"),
        lidFillColor: root.getPropertyValue("--sl-color-primary-200"),
        lidTextColor: root.getPropertyValue("--sl-color-neutral-800"),
        portColor: root.getPropertyValue("--sl-color-red-400"),
        stbdColor: root.getPropertyValue("--sl-color-green-400"),
        addRowLines: true,
      },
      svgGroupId: SVG_G_NAME,
    });

    return {
      deckViewSvg,
      missingImportantXcgs,
      xPos,
      xRange,
      zPos,
      zRange,
      addX,
      lcgsBy20Bay,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
}

function mustBeOdd(ev: Event): void {
  const t = ev.target as SlInput;

  if (t.disabled || t.value === "") {
    t.classList.remove("has-error");
    return;
  }

  const n = Number(t.value);

  if (isNaN(n) || n % 2 === 0) {
    if (!t.classList.contains("has-error")) t.classList.add("has-error");
  } else {
    t.classList.remove("has-error");
  }
}

function flipDeckSvg(svg: SVGElement): void {
  const width = svg.getAttribute("width");
  const height = svg.getAttribute("height");
  const viewBox = svg.getAttribute("viewBox")?.split(" ");

  svg.setAttribute("width", String(height));
  svg.setAttribute("height", String(width));
  svg.style.maxWidth = "400px";

  svg.setAttribute("data-time", String(Date.now()));

  if (viewBox) {
    svg.setAttribute(
      "viewBox",
      `${viewBox[1]} ${viewBox[0]} ${viewBox[3]} ${viewBox[2]}`,
    );
  }

  const g: SVGGElement | null = svg.querySelector(`#${SVG_G_NAME}`);
  if (g) {
    g.setAttribute(
      "transform",
      `rotate(-90, 0, ${Number(width) * 0.5}) translate(${
        Number(width) * -0.5
      }, ${Number(width) * 0.5})`,
    );
  }
}

function findParentTr(t: HTMLElement): HTMLElement | null {
  let p = t.parentElement;
  while (p !== null) {
    if (p.nodeName === "TR") return p;
    p = p.parentElement;
  }
  return null;
}

interface ISvgDetails {
  deckViewSvg: SVGElement;
  missingImportantXcgs: boolean;
  xRange: number;
  zRange: number;
  xPos: (lcg: number, d?: number | undefined) => number;
  zPos: (tcg: number, d?: number | undefined) => number;
  addX: number;
  lcgsBy20Bay: I20LcgsByBay;
}

interface IDeckSlotsPositions {
  [name: string]: {
    minX: number;
    maxX: number;
    minZ: number;
    maxZ: number;
  };
}
