/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  AuthenticationDetails,
  ChallengeName,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationCallback,
  NodeCallback,
} from "amazon-cognito-identity-js";
import {
  ICognitoEventClaimsBase,
  ICognitoEventClaimsForFE,
  parseCognitoClaimsForFe,
} from "./ICognitoEventClaims";

import type { IOrganizationsCognitoAttributeAugmented } from "@baplie-viewer2/tedivo-api-models";
import { errorTracking } from "../tracking/errorTracking";
import { getPreferencesValue } from "@baplie-viewer2/tedivo-preferences";
import goSquared from "../tracking/goSquared";

declare interface TvdAwsCognito {
  addEventListener(
    event: "authenticationChanged",
    listener: (ev: TAuthenticateEvent) => void,
  ): this;
  addEventListener(
    event: "userDataRetrieved",
    listener: (
      ev: CustomEvent<{ detail: IOrganizationsCognitoAttributeAugmented }>,
    ) => void,
  ): this;
  addEventListener(
    event: "sessionTimedOut",
    listener: (ev: Event) => void,
  ): this;

  addEventListener(event: string, listener: () => void): this;
}

/**
 * Class for AWSCognito API calls
 */
class TvdAwsCognito extends EventTarget {
  private currentCognitoSession?: CognitoUserSession = undefined;
  private currentEmail?: string = undefined;
  private previousEmail?: string = undefined;

  private _cognitoEventClaims?: ICognitoEventClaimsForFE = undefined;
  private _currentOrgSecurityData?: IOrganizationsCognitoAttributeAugmented =
    undefined;
  private _userEditableDetails: {
    name: string;
    familyName: string;
    email: string;
  } = {
    name: "",
    familyName: "",
    email: "",
  };

  private jwtToken?: string = undefined;
  private accessTokenInternal?: string = undefined;
  private cognitoUserInstance?: CognitoUser = undefined;
  private refreshToken?: CognitoRefreshToken = undefined;
  private refreshTokenState: "idle" | "refreshing" = "idle";

  public tempAcceptsMarketingMails: boolean | undefined = false;
  public orgUsersBySub: Record<string, string> | undefined = undefined;

  private _mfaEnabled: string | undefined = undefined;
  public get isMfaEnabled() {
    return this._mfaEnabled;
  }

  public setMfaFlagAsDisabled = () => {
    this._mfaEnabled = undefined;
  };

  private configUserPoolId = process.env.NX_PUBLIC_USER_POOL_ID || "";
  private configClientId = process.env.NX_PUBLIC_USER_APP_CLIENT_ID || "";
  private get poolData() {
    return {
      UserPoolId: this.configUserPoolId,
      ClientId: this.configClientId,
    };
  }

  constructor() {
    super();
    const stage = process.env.NX_PUBLIC_STAGE;
    if (stage === "dev" || stage === "alpha") window.awsCognito = this;
  }

  private createCognitoUserInstance(
    username: string,
    force = false,
  ): CognitoUser {
    if (!this.cognitoUserInstance || force) {
      const userPool = new CognitoUserPool(this.poolData);
      const userData = { Username: username, Pool: userPool };
      this.cognitoUserInstance = new CognitoUser(userData);
    }

    return this.cognitoUserInstance;
  }

  public get isLoggedIn() {
    return !!this.currentCognitoSession;
  }

  public get userSub(): string | undefined {
    return this._cognitoEventClaims?.sub;
  }

  public get email() {
    return this.currentEmail;
  }

  get userMarketingMails(): boolean {
    return this._cognitoEventClaims?.["custom:marketing"] || false;
  }

  get userEditableDetails() {
    return { ...this._userEditableDetails };
  }

  // #region Auth tokens info
  public get sessionExpires(): Date | null {
    const dtStr = this._cognitoEventClaims?.exp;
    return dtStr ? new Date(dtStr) : null;
  }

  public get idToken(): string | undefined {
    return this.jwtToken;
  }

  public get accessToken(): string | undefined {
    return this.accessTokenInternal;
  }
  // #endregion

  // #region Current Organization data
  public get currentOrganizationId(): string | undefined {
    return this._currentOrgSecurityData?.orgId;
  }

  public get currentOrgSecurityData():
    | IOrganizationsCognitoAttributeAugmented
    | undefined {
    return this._currentOrgSecurityData;
  }

  get orgAllowedDomains(): string[] {
    return (this._currentOrgSecurityData?.domains || "").split(",") || [];
  }

  get orgMfaForAllUsers(): boolean {
    return this._currentOrgSecurityData?.mfaAll === "1" || false;
  }

  set orgMfaForAllUsers(v: boolean) {
    if (!this._currentOrgSecurityData) return;
    this._currentOrgSecurityData.mfaAll = v ? "1" : "0";
  }

  get isLoginInfoComplete(): boolean {
    return !!this._cognitoEventClaims;
  }

  get allOrganizationsInfo(): IOrganizationsCognitoAttributeAugmented[] {
    return this._cognitoEventClaims?.organizations || [];
  }

  // #endregion

  // private get accountExpirationDate(): Date | undefined {
  //   const expiresAt =
  //     this._cognitoEventClaims?.orgAdminOptions?.subscription?.expiresAt;

  //   if (typeof expiresAt === "string") return new Date(expiresAt as string);
  //   else if (expiresAt) return expiresAt;

  //   return undefined;
  // }

  public changeCurrentOrganization = (orgId: string): boolean => {
    if (orgId === this._currentOrgSecurityData?.orgId) return false;

    const orgRel = this._cognitoEventClaims?.organizations.find(
      (o) => o.orgId === orgId && o.uEnabled === "1",
    );

    if (orgRel) {
      this._currentOrgSecurityData = orgRel;
      window.setTimeout(() => {
        this.dispatchEvent(
          new CustomEvent("userDataRetrieved", { detail: orgRel }),
        );
      }, 0);
      return true;
    } else {
      console.error("Unable to set invalid organization", orgId);
      return false;
    }
  };

  /**
   * This is only used the first time the browser loads, to see if there is an existing valid session
   */
  public checkIfUserIsLoggedIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool(this.poolData);
      const userFromPool = userPool.getCurrentUser() as {
        username: string;
        userDataKey: string;
      } | null;

      if (!userFromPool) {
        this.dispatchEvent(
          new CustomEvent("authenticationChanged", {
            detail: { isLoggedIn: false },
          }),
        );
        return resolve(false);
      }

      try {
        const userData = { Username: userFromPool.username, Pool: userPool };
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.getSession(
          (_error: Error | null, session?: CognitoUserSession) => {
            if (session) {
              // Resolve false if expired
              if (!session.isValid()) {
                this.clearSession();
                resolve(false);
                return;
              }

              this.currentCognitoSession = session;
              this.cognitoUserInstance = cognitoUser;

              this.jwtToken = session.getIdToken().getJwtToken();
              this.accessTokenInternal = session.getAccessToken().getJwtToken();
              this.refreshToken = session.getRefreshToken();

              if (userFromPool.userDataKey) {
                const dataString = localStorage.getItem(
                  userFromPool.userDataKey,
                );
                if (dataString) {
                  this._cognitoEventClaims = JSON.parse(dataString);
                }
              }

              this.getMfaOptions();

              if (this.processCognitoSessionClaimsForFE()) {
                this.dispatchEvent(
                  new CustomEvent("authenticationChanged", {
                    detail: { isLoggedIn: true },
                  }),
                );
                resolve(true);
              } else {
                this.dispatchEvent(
                  new CustomEvent("authenticationChanged", {
                    detail: { isLoggedIn: false },
                  }),
                );
                resolve(false);
              }
            } else {
              this.currentCognitoSession = undefined;
              this.dispatchEvent(
                new CustomEvent("authenticationChanged", {
                  detail: { isLoggedIn: false },
                }),
              );
              resolve(false);
            }
          },
        );
      } catch (e) {
        this.dispatchEvent(
          new CustomEvent("authenticationChanged", {
            detail: { isLoggedIn: false },
          }),
        );
        reject(e);
      }
    });
  }

  /**
   * Authenticates a user in Cognito
   */
  public authenticateUser(
    username: string,
    password: string,
  ): Promise<IAwsAuthResponse> {
    return new Promise<IAwsAuthResponse>((resolve) => {
      const authenticationData = {
        Username: username,
        Password: password,
      };

      if (!authenticationData.Username || !authenticationData.Password) {
        resolve({ code: "loginMissingData" });
        return;
      }

      const authenticationDetails = new AuthenticationDetails(
        authenticationData,
      );

      const cognitoUser = this.createCognitoUserInstance(
        username,
        this.previousEmail !== username,
      );

      cognitoUser.authenticateUser(
        authenticationDetails,
        this.createAuthCallbacks(cognitoUser, username, resolve),
      );
    });
  }

  public setNewPassword(
    username: string,
    newPassword: string,
  ): Promise<IAwsAuthResponse> {
    return new Promise((resolve, reject) => {
      if (!username) {
        reject({ code: "notLoggedIn" });
        return;
      }

      const cognitoUser = this.createCognitoUserInstance(username);

      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        [],
        this.createAuthCallbacks(cognitoUser, username, resolve),
      );
    });
  }

  public updateUserDetails(
    name: string,
    familyName: string,
    email: string,
    marketingMails: boolean | undefined = undefined,
  ) {
    if (!this._cognitoEventClaims) return;

    this._userEditableDetails.name = name;
    this._userEditableDetails.familyName = familyName;

    if (email) this._userEditableDetails.email = email;

    if (marketingMails !== undefined)
      this._cognitoEventClaims["custom:marketing"] = marketingMails;
  }

  public processCognitoSessionClaimsForFE(): boolean {
    const idTokenAccessor = this.currentCognitoSession?.getIdToken();
    const isValid = this.currentCognitoSession?.isValid();

    const idTokenPayload = idTokenAccessor?.payload as
      | ICognitoEventClaimsBase
      | undefined;

    if (!idTokenPayload || !isValid) {
      this._cognitoEventClaims = undefined;
      return false; // -> Fast Return. No valid session
    }

    const cognitoClaimsForFE = parseCognitoClaimsForFe(
      idTokenPayload as ICognitoEventClaimsBase,
    );

    this._cognitoEventClaims = cognitoClaimsForFE;
    this.tempAcceptsMarketingMails = undefined;

    this.updateUserDetails(
      cognitoClaimsForFE.name,
      cognitoClaimsForFE.family_name,
      cognitoClaimsForFE.email,
      undefined,
    );

    if (cognitoClaimsForFE.organizations.length === 1) {
      const defaultOrgId = cognitoClaimsForFE.organizations[0].orgId;
      this.changeCurrentOrganization(defaultOrgId);
    } else {
      const preferredOrgId = getPreferencesValue("preferredOrgId") as string;
      if (preferredOrgId) this.changeCurrentOrganization(preferredOrgId);
    }

    goSquared.identify({
      email: cognitoClaimsForFE.email,
      name: `${cognitoClaimsForFE.name} ${cognitoClaimsForFE.family_name}`,
      marketingMailsConsent:
        this.tempAcceptsMarketingMails !== undefined
          ? this.tempAcceptsMarketingMails
          : cognitoClaimsForFE["custom:marketing"],
    } as any);

    return true;
  }

  private tryToRefreshToken(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.currentCognitoSession || !this.cognitoUserInstance) {
        this.refreshTokenState = "idle";
        resolve(false);
        return;
      }

      this.refreshTokenState = "refreshing";

      if (this.refreshToken !== undefined) {
        this.cognitoUserInstance.refreshSession(
          this.refreshToken,
          (err, session: CognitoUserSession) => {
            console.log("refreshSession", err, session);
            if (err) {
              this.signOut();
              resolve(false);
            } else {
              this.currentCognitoSession = session;
              this.jwtToken = session.getIdToken().getJwtToken();
              this.refreshToken = session.getRefreshToken();
              this.accessTokenInternal = session.getAccessToken().getJwtToken();

              this.processCognitoSessionClaimsForFE();

              console.log({
                newsessionExpires: this.sessionExpires?.toISOString(),
              });

              resolve(true);
            }
            this.refreshTokenState = "idle";
          },
        );
      } else {
        resolve(false);
      }
    });
  }

  public async signOut() {
    return new Promise((resolve) => {
      const sOutCb = () => {
        this.clearSession();
        resolve(true);
      };

      if (this.currentCognitoSession && this.cognitoUserInstance) {
        this.cognitoUserInstance.globalSignOut({
          onSuccess: () => {
            sOutCb();
          },
          onFailure: (err) => {
            errorTracking.leaveBreadcrumb("Cognito signOut error", err);
            sOutCb();
          },
        });
      } else {
        sOutCb();
      }
    });
  }

  public changePassword = (
    username: string,
    oldPassword: string,
    newPassword: string,
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.createCognitoUserInstance(username);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const callback: NodeCallback<Error, "SUCCESS"> = (err, _success) => {
        if (err) {
          reject(false);
        } else {
          resolve(true);
        }
      };

      cognitoUser.changePassword(oldPassword, newPassword, callback);
    });
  };

  public clearSession() {
    this.orgUsersBySub = undefined;
    this._cognitoEventClaims = undefined;
    this.currentCognitoSession = undefined;
    this.currentEmail = undefined;
    this.cognitoUserInstance = undefined;
    this._currentOrgSecurityData = undefined;

    errorTracking.leaveBreadcrumb("Cognito session cleared");
    goSquared.unIdentify();

    setTimeout(() => {
      this.dispatchEvent(
        new CustomEvent("authenticationChanged", {
          detail: { isLoggedIn: false },
        }),
      );
      this.dispatchEvent(new CustomEvent("sessionTimedOut"));
    }, 250);
  }

  public associateMfaDevice = ({
    associateSecretCode,
    onFailure,
  }: {
    associateSecretCode: (secretCode: string) => void;
    onFailure: (err: any) => void;
  }) => {
    if (!this.cognitoUserInstance) {
      return false;
    }

    this.cognitoUserInstance.associateSoftwareToken({
      associateSecretCode,
      onFailure,
    });

    return true;
  };

  /**
   * This is called when the user has entered the MFA code for the first time and we need to verify it
   * @param code
   * @returns
   */
  public verifyAndFinishMfaDeviceAssociation = (
    code: string,
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      if (!this.cognitoUserInstance) {
        return Promise.resolve(false);
      }

      this.cognitoUserInstance.verifySoftwareToken(code, getDeviceName(), {
        onSuccess: async (session: any) => {
          const res = session.Status === "SUCCESS";

          if (res) {
            await this.enableMfa(true);
            resolve(true);
          } else {
            resolve(false);
          }
        },
        onFailure: (err) => {
          resolve(false);
          console.log(err);
        },
      });
    });
  };

  public enableMfa = (enable: boolean) => {
    return new Promise((resolve) => {
      this.cognitoUserInstance?.setUserMfaPreference(
        null,
        { PreferredMfa: enable, Enabled: enable },
        (err) => {
          console.log({ err });
          resolve(!err);
        },
      );
    });
  };

  public verifyTopTCode = (
    code: string,
    username: string,
  ): Promise<IAwsAuthResponse> => {
    return new Promise<IAwsAuthResponse>((resolve) => {
      if (!this.cognitoUserInstance) {
        return Promise.resolve(false);
      }

      const cognitoUser = this.createCognitoUserInstance(username);

      this.cognitoUserInstance.sendMFACode(
        code,
        this.createAuthCallbacks(cognitoUser, username, resolve),
        "SOFTWARE_TOKEN_MFA",
      );
    });
  };

  private createAuthCallbacks = (
    cognitoUser: CognitoUser,
    username: string,
    resolve: (value: IAwsAuthResponse | PromiseLike<IAwsAuthResponse>) => void,
  ): IAuthenticationCallback => {
    return {
      onSuccess: (session) => {
        cognitoUser.setSignInUserSession(session);
        this.currentEmail = username;
        this.currentCognitoSession = session;
        this.previousEmail = username;

        const accessToken = session.getAccessToken().getJwtToken();
        const idToken = session.getIdToken().getJwtToken();

        /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
        this.jwtToken = idToken;
        this.refreshToken = session.getRefreshToken();
        this.accessTokenInternal = session.getAccessToken().getJwtToken();

        if (this.processCognitoSessionClaimsForFE()) {
          this.dispatchEvent(
            new CustomEvent("authenticationChanged", {
              detail: { isLoggedIn: true },
            }),
          );
          this.getMfaOptions();
          resolve({ code: "ok", accessToken, idToken });
        } else {
          this._mfaEnabled = undefined;
          this.dispatchEvent(
            new CustomEvent("authenticationChanged", {
              detail: { isLoggedIn: false },
            }),
          );
          resolve({ code: "failure", error: "Failed to compose user data" });
        }
      },

      onFailure: (err) => {
        console.log("onFailure", err);

        if (err && err.code === "UserLambdaValidationException") {
          err.message = err.message.split("enabled=").pop().replace(/\.$/, "");
        }

        resolve({ code: "failure", error: err });
      },

      newPasswordRequired: (userAttrs: any, reqUserAttrs: any) => {
        resolve({ code: "newPasswordRequired", userAttrs, reqUserAttrs });
      },

      customChallenge: (params: any) => {
        resolve({ code: "customChallenge", params });
      },

      totpRequired: (
        challengeName: ChallengeName,
        challengeParameters: any,
      ) => {
        resolve({
          code: "totpRequired",
          challengeName,
          challengeParameters,
        });
      },
    };
  };

  /** If the user is active, try to refresh the Tokens 20 minutes before the session ends */
  public initiateRefreshToken() {
    const MIN_REFRESH_TIME = 20 * 60 * 1000; // 20 minutes before session expires, try to refresh the token if criteria is met

    if (!this.currentCognitoSession?.isValid()) {
      this.signOut();
      return;
    }

    const sessionTimesOutInMilliseconds =
      (this.sessionExpires as Date).getTime() - Date.now();

    const remaining = sessionTimesOutInMilliseconds - MIN_REFRESH_TIME;

    if (remaining > 0 || this.refreshTokenState === "refreshing") {
      return;
    }

    if (sessionTimesOutInMilliseconds < 0) {
      this.signOut();
      return;
    }

    this.tryToRefreshToken();
  }

  /** Update the name (temporarily) to show the correct Organization Name */
  public updateOrganizationName(organizationName: string) {
    if (!this.currentOrgSecurityData) return;
    this.currentOrgSecurityData.name = organizationName;
  }

  /** Ask Cognito back-end and register MFA internally */
  public getMfaOptions() {
    if (!this.cognitoUserInstance) {
      return Promise.resolve(false);
    }

    this.cognitoUserInstance.getUserData((err, data) => {
      if (err || !data) {
        console.log(err);
        this.dispatchEvent(new CustomEvent("sessionTimedOut"));
        return;
      }

      this._mfaEnabled = data.PreferredMfaSetting;
    });
  }
}

/**
 * Singleton of TvdAwsCognito, an API to interact with Cognito Pool
 */
const awsCognito = new TvdAwsCognito();

export default awsCognito;

export interface IAwsAuthResponse {
  code:
    | "loginMissingData"
    | "ok"
    | "failure"
    | "newPasswordRequired"
    | "customChallenge"
    | "totpRequired";
  [name: string]: unknown;
}

export interface ICreateUserProps {
  email: string;
  password: string;
  name: string;
  familyName: string;
  company?: string;
}

interface Error {
  name: string;
  message: string;
  row?: string;
}

type TAuthenticateEvent = CustomEvent<{ isLoggedIn: boolean }>;

function getDeviceName() {
  return `TVD${
    process.env.NX_PUBLIC_STAGE === "prod"
      ? ""
      : `-${process.env.NX_PUBLIC_STAGE}`
  }`;
}
