import "./view-json.component.scss";

import {
  IFileState,
  IFilesTags,
  systemRights,
} from "@baplie-viewer2/tedivo-api-models";
import {
  IGetBayLcgVcgAndPairingsResult,
  getBayLcgVcgTcgAndPairings,
  validateLidData,
} from "@baplie-viewer2/tedivo-bay-grid-core";
import {
  IOpenVesselDefinitionV1,
  LcgReferenceEnum,
  ValuesSourceEnum,
} from "open-vessel-definition";
import {
  ITedivoShoelaceSelectOptions,
  SelectShoelace,
  createEditButton,
  createMultiEditButton,
  createSelectShoelace,
} from "@baplie-viewer2/tedivo-form";
import { ViewJsonTypeEnum, getViewTypesFromRouteKey } from "./ViewJsonTypeEnum";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import ovdJsonStore, {
  ITvdCloudFileMeta,
} from "../../../app/stores/OVDJsonStore";

import CellSpacingEnum from "../../../app/enums/CellSpacingEnum";
import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import EditDrawer from "../../common/EditDrawer";
import FileStateEnum from "../../../app/enums/FileStateEnum";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import { IViewHistoryState } from "../open-json/open-json.component";
import { ImoFoundResultEnum } from "../import-staf/ImoFoundResultEnum";
import IntegratedDialog from "../../common/IntegratedDialog";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import SizeSmallMidLargeEnum from "../../../app/enums/SizeSmallMidLargeEnum";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import SlSpinner from "@shoelace-style/shoelace/dist/components/spinner/spinner";
import { TitleWithActions } from "../../common/titleWithActions/title-with-actions.component";
import UoLcgReferenceEnum from "../../../app/enums/UoLcgReferenceEnum";
import VcgVerticalReferenceEnum from "../../../app/enums/VcgVerticalReferenceEnum";
import { buildConfigObjects } from "./config/viewOssConfig";
import { calculateMissingCGs } from "./parts/edits/helpers/editCGs/calculateMissingCGs";
import { calculateTeusFromSlots } from "tedivo-bay-grid-pure";
import { createBaySection } from "./parts/createBaySection";
import { createBulkheadsEdit } from "./parts/edits/createBulkheadsEdit";
import { createCGsEdit } from "./parts/edits/createCGsEdit";
import { createCGsMasterEdit } from "./parts/edits/createCGsMasterEdit";
import { createDeckView } from "./parts/createDeckView";
import { createDownloadOVDJsonLink } from "./parts/createDownloadOVDJsonLink";
import { createFoundImoDialog } from "../import-staf/createFoundImoDialog";
import { createGeneralEdit } from "./parts/edits/createGeneralEdit";
import { createLidsEdit } from "./parts/edits/createLidsEdit";
import { createLppEdit } from "./parts/edits/createLppEdit";
import { createMissingCGsDialog } from "./parts/edits/helpers/editCGs/createMissingCGsDialog";
import { createModalToForNotes } from "./parts/createModalToForNotes";
import { createModalToTagFiles } from "./parts/createModalToTagFiles";
import { createNotFoundImoDialog } from "../import-staf/createNotFoundImoDialog";
import { createPart } from "./parts/createPart";
import { createRemoveCGsDialog } from "./parts/edits/helpers/editCGs/createRemoveCGsDialog";
import { createSideView } from "./parts/createSideView";
import { createSizeEdit } from "./parts/edits/createSizeEdit";
import { createVersionsDropdown } from "../../helpers/createVersionsDropdown";
import { getPreferencesValue } from "@baplie-viewer2/tedivo-preferences";
import globalStore from "../../../app/stores/globalStore";
import globalUnits from "../../../app/units/globalUnits";
import goSquared from "../../../app/tracking/goSquared";
import { removeCGsfromJson } from "./serv/removeCGsfromJson";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import { showFileHistory } from "./showFileHistory";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
import { vcgRefToFactor } from "./parts/consts";

export class TVDViewJsonComponent extends HTMLElement {
  public static observedAttributes = [];

  public static optionsByViewType: Record<
    ViewJsonTypeEnum,
    { pageTrackTitle: string; eventTrackPrefix: string; h1Title: string }
  > = {
    [ViewJsonTypeEnum.EDIT]: {
      pageTrackTitle: "Define OVD",
      eventTrackPrefix: "Edit-OVD",
      h1Title: "view:pageTitleEdit",
    },
    [ViewJsonTypeEnum.VIEW_OVD]: {
      pageTrackTitle: "View TVL OVD",
      eventTrackPrefix: "VIEW-OVD",
      h1Title: "view:pageTitleView",
    },
    [ViewJsonTypeEnum.VIEW_VERSION]: {
      pageTrackTitle: "View OVD own version",
      eventTrackPrefix: "VIEW-OWN-OVD-VERSION",
      h1Title: "view:pageTitleViewVersion",
    },
  };

  private i18nCR: I18nComponentRegisterer;
  private bayDrawOptions = getPreferences();
  private mainNode: DivWithSpinner;
  private dialogError: IntegratedDialogError = new IntegratedDialogError(this);
  private editDrawer: EditDrawer;
  private confirmChangeStateDialog: SlDialog | undefined = undefined;
  private dialogHistory: SlDialog;
  private dialogTagFile = new IntegratedDialog({ parentNode: this });
  private dialogFileNotes = new IntegratedDialog({ parentNode: this });
  private titleWithActions: TitleWithActions | undefined = undefined;

  private readonlyMode = securityModule.planIsReadOnly;
  private canOpenDrawer = true;

  private notifiedStateChanged = false;
  private intNotifiedStateChanged = 0;
  private loadingIndicator: SlSpinner;

  private organizationUsersBySub: Record<string, string> = {};
  private fileTags: IFilesTags = { tags: {}, filesByTag: {} };

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.mainNode = new DivWithSpinner();
    this.editDrawer = new EditDrawer();

    this.loadingIndicator = document.createElement("sl-spinner");
    this.showSmallLoadingInTitle = false;

    this.dialogHistory = document.createElement("sl-dialog");

    ovdJsonStore.readonlyMode = securityModule.planIsReadOnly;
  }

  set showSmallLoadingInTitle(v: boolean) {
    this.loadingIndicator.style.display = v ? "inline-block" : "none";
  }

  connectedCallback() {
    const viewType = getViewTypesFromRouteKey(router.currentRouteKey);
    if (viewType === undefined) {
      router.navigate(routeFns.myCloud());
      return;
    }

    const optionsByViewType = TVDViewJsonComponent.optionsByViewType[viewType];

    goSquared.trackPageAndEvent(
      optionsByViewType.pageTrackTitle,
      `${optionsByViewType.eventTrackPrefix} - Show page`,
    );

    this.dialogHistory.setAttribute("style", "--width: 80vw");
    this.dialogHistory.label = getTranslation("view:edit.fileHistory.title");

    this.dialogFileNotes.updateOptions({ width: "80vw" });

    const state = router.currentState as IViewHistoryState;

    this.showSmallLoadingInTitle = false;

    // Append Nodes
    this.appendChild(this.editDrawer.drawer);
    this.appendChild(this.mainNode);
    this.appendChild(this.dialogHistory);

    // Translations
    const topEle = topMessageElement.element;
    if (topEle)
      topEle.innerHTML = getTranslation(optionsByViewType.h1Title, {
        organizationName:
          state?.organizationName || securityModule.currentOrganizationName,
      });

    // In case a previous session was opened
    this.editDrawer.drawer.hide();

    // Listeners ******
    const body = document.documentElement;

    i18nReactive.addEventListener("updateI18Consumers", this.showData);

    ovdJsonStore.addEventListener("jsonUpdated", this.showData);
    ovdJsonStore.addEventListener("stateChanged", this.onStateChanged);
    ovdJsonStore.addEventListener("updatingDB", this.onUpdatingDB);
    ovdJsonStore.addEventListener("error", this.onErrorNavigateMyCloud);

    body.addEventListener("colorModeChanged", this.showData);
    body.addEventListener("globalUnitsChanged", this.showData);

    // Act
    this.handleData(state, viewType);

    securityModule
      .getOrganizationUsersBySub(securityModule.currentOrganizationId)
      .then((organizationUsersBySub) => {
        if (organizationUsersBySub !== undefined) {
          this.organizationUsersBySub = organizationUsersBySub;
        }
      });
  }

  private onStateChanged = ((
    ev: CustomEvent<{ oldState: IFileState; newState: IFileState }>,
  ): void => {
    this.notifyStateChanged(ev.detail);
  }) as EventListener;

  private onUpdatingDB = ((ev: CustomEvent<{ isUpdating: boolean }>): void => {
    this.showSmallLoadingInTitle = ev.detail.isUpdating;
  }) as EventListener;

  private onErrorNavigateMyCloud = ((ev: Event): void => {
    router.navigate(routeFns.myCloud());
  }) as EventListener;

  disconnectedCallback() {
    this.i18nCR.disconnect();

    document.documentElement.removeEventListener(
      "colorModeChanged",
      this.showData,
    );

    document.documentElement.removeEventListener(
      "globalUnitsChanged",
      this.showData,
    );

    i18nReactive.removeEventListener("updateI18Consumers", this.showData);

    ovdJsonStore.removeEventListener("jsonUpdated", this.showData);
    ovdJsonStore.removeEventListener("stateChanged", this.onStateChanged);
    ovdJsonStore.removeEventListener("updatingDB", this.onUpdatingDB);
    ovdJsonStore.removeEventListener("error", this.onErrorNavigateMyCloud);
  }

  async handleData(state: IViewHistoryState, viewType: ViewJsonTypeEnum) {
    const isViewMode = viewType !== ViewJsonTypeEnum.EDIT;

    if (state) {
      ovdJsonStore.tvdId = undefined;

      this.readonlyMode = isViewMode || securityModule.planIsReadOnly;
      ovdJsonStore.readonlyMode =
        securityModule.planIsReadOnly || (isViewMode && !!state.impersonate);

      const version =
        (viewType === ViewJsonTypeEnum.VIEW_VERSION &&
          router.getRouteParams().version) ||
        undefined;

      switch (state.source) {
        case "ovdJson":
          this.mainNode.setLoading(true);

          Services.ovdJson.readFile
            .readOVDJsonFile(state.file)
            .then(async (json) => {
              await ovdJsonStore
                .setJson(state.filenameParts.name, json)
                .saveToCloud("OpenOVD");

              const tvdId = ovdJsonStore.tvdId as ITvdCloudFileMeta | undefined;
              if (tvdId !== undefined) {
                this.mainNode.setLoading(false);
                router.navigate(
                  routeFns.ovdEdit(tvdId.fileId),
                  undefined,
                  true,
                );
              }
            })
            .catch((e) => {
              this.showError(
                e as ICustomError,
                (e as any).message || "errors:notValidOvdFile",
              );

              this.mainNode.setLoading(false);
              if (e.statusCode === 402) router.navigate(routeFns.myCloud());
            });
          break;
        case "stafText":
          this.mainNode.setLoading(true);

          try {
            const formFields = state.formFields;

            const jsonConv = await Services.importStaf.stafFileConvertToOVD(
              state.file,
              vcgRefToFactor[formFields.vcgRef],
              formFields.lpp,
              formFields.aboveTier82is,
            );

            const json = formFields.removeCGs
              ? removeCGsfromJson(jsonConv)
              : jsonConv;

            await ovdJsonStore
              .setJson(formFields.shipName, json)
              .setShipDataGeneralInfo(
                {
                  ...json.shipData,
                  shipClass: formFields.shipClass,
                  shipName: formFields.shipName,
                  lineOperator: formFields.lineOperator,
                  imoCode: formFields.imoCode,
                  yearBuilt: formFields.yearBuilt,
                  callSign: formFields.callSign,
                  loa: formFields.loa,
                  featuresAllowed: undefined,
                },
                false, // Don't emit change and call saveToCloud explicitly
              )
              .saveToCloud("ImportSTAF");

            const tvdId = ovdJsonStore.tvdId as ITvdCloudFileMeta | undefined;
            if (tvdId !== undefined) {
              this.mainNode.setLoading(false);
              router.navigate(routeFns.ovdEdit(tvdId.fileId), undefined, true);
            }
          } catch (e: ICustomError | any) {
            this.showError(
              e as ICustomError,
              (e as any).message || "errors:notValidStafFile",
            );
            if (e.statusCode === 402) router.navigate(routeFns.myCloud());
          }

          break;
        case "newOvd":
          try {
            ovdJsonStore
              .setJson(state.filenameParts.name, state.json)
              .saveToCloud("NewOVD")
              .then(() => {
                if (ovdJsonStore.tvdId) {
                  router.navigate(
                    routeFns.ovdEdit(ovdJsonStore.tvdId.fileId),
                    undefined,
                    true,
                  );
                }
              });
          } catch (e: ICustomError | any) {
            this.showError(
              e as ICustomError,
              (e as any).message || "errors:notValidOvdFile",
            );
            if (e.statusCode === 402) router.navigate(routeFns.myCloud());
          }

          this.showData();
          break;
        case "cloudOvdJson":
          await getAndShowOvdFromCloud({
            cloudId: state.cloudId,
            version,
            organizationId: state.organizationId,
            isViewMode,
            mainNode: this.mainNode,
            onSuccess: () => this.showData(),
          });

          break;
      }
    } else {
      const cloudId = router.getRouteParams().id;

      if (!cloudId) {
        router.navigate(routeFns.myCloud());
        return;
      }

      await getAndShowOvdFromCloud({
        cloudId,
        version: undefined,
        organizationId: undefined,
        isViewMode,
        mainNode: this.mainNode,
        onSuccess: () => this.showData(),
      });
    }

    async function getAndShowOvdFromCloud({
      cloudId,
      version,
      organizationId,
      isViewMode,
      mainNode,
      onSuccess,
    }: {
      cloudId: string;
      version: string | undefined;
      organizationId: string | undefined;
      isViewMode: boolean;
      mainNode: DivWithSpinner;
      onSuccess: () => void;
    }) {
      try {
        goSquared.addEvent(
          `${TVDViewJsonComponent.optionsByViewType[viewType].eventTrackPrefix} - Load from cloud`,
        );

        const dataFromCloud = await mainNode.withLoading(() =>
          getCloudOvdJson(cloudId, organizationId, version),
        );

        if (dataFromCloud) {
          ovdJsonStore.readonlyMode =
            securityModule.planIsReadOnly ||
            (isViewMode && !!state?.impersonate);

          ovdJsonStore.setJson(dataFromCloud.name, dataFromCloud.json);

          ovdJsonStore.tvdId = {
            fileId: cloudId,
            userSub: dataFromCloud.userSub,
            organizationId: dataFromCloud.organizationId,
            organizationName:
              state?.organizationName || securityModule.currentOrganizationName,
            fileState: dataFromCloud.fileState,
            createdAt: dataFromCloud.createdAt,
            updatedAt: dataFromCloud.lastModified,
            updatedBy: dataFromCloud.lastModifiedBy,
            fromBvoName: dataFromCloud.fromBvoName,
            lastComment: dataFromCloud.lastComment,
          };

          if (!dataFromCloud.json.shipData.lcgOptions?.lpp)
            setLcgRefViewOptionsToAftPerpFwd();

          onSuccess();
        } else {
          throw new Error("File not found");
        }
      } catch (e) {
        console.error(e);
        document.body.dispatchEvent(
          new CustomEvent<ICustomError>("customError", {
            detail: {
              errorCode: getTranslation((e as any).message),
              message: "errors:errorHasOcurred",
              translationKey: getTranslation((e as any).message),
            },
          }),
        );
        router.navigate(routeFns.myCloud());
      }
    }
  }

  showData = () => {
    globalStore.touchLastUserInteraction();

    const viewType = getViewTypesFromRouteKey(
      router.currentRouteKey,
    ) as ViewJsonTypeEnum;

    this.canOpenDrawer =
      securityModule.currentOrganizationId ===
        ovdJsonStore.tvdId?.organizationId ||
      securityModule.userHasPermission(systemRights.ORG.CanDownloadPublicFile);

    const json = ovdJsonStore.currentJson;

    if (!json) {
      router.navigate(routeFns.ovdOpen());
      return;
    }

    const version =
      (viewType === ViewJsonTypeEnum.VIEW_VERSION &&
        router.getRouteParams().version) ||
      undefined;

    this.mainNode.clearChildren();

    setAppTitle(
      `${getTranslation(
        TVDViewJsonComponent.optionsByViewType[viewType].h1Title,
        {
          organizationName:
            ovdJsonStore.tvdId?.organizationName ||
            securityModule.currentOrganizationName,
        },
      )} : ${[json.shipData.shipName || "?", json.shipData.imoCode || ""].join(
        " : ",
      )}`,
    );

    this.showSmallLoadingInTitle = false;

    const lcgVcgTcgAndPairings = getBayLcgVcgTcgAndPairings(
      json.baysData,
      json.shipData.masterCGs,
    );

    const {
      shipPartDataElements,
      sizeSummaryDataElements,
      cgsPartDataElements,
    } = buildConfigObjects(json, {
      cgsStats: lcgVcgTcgAndPairings.cgsStats,
      totalTEUs: calculateTeusFromSlots(json.baysData),
    });

    const editCGsButton = createMultiEditButton({
      id: `editCgs`,
      className: "bay-edit-btn",
      title: getTranslation("view:edit.cgs"),
      disabled: !this.canOpenDrawer,
      options: [
        {
          value: "editLpp",
          name: getTranslation("view:edit.lpp"),
          action: () => {
            const editForm = createLppEdit(
              json.shipData,
              this.editDrawer.submitButton,
            );

            if (editForm) {
              const drawer = this.editDrawer.getEditDrawer({
                title: getTranslation("view:edit.lpp"),
                onSave: editForm.submitFunction,
                readOnlyMode: this.readonlyMode,
              });
              drawer.appendChild(editForm.node);
              drawer.show();
            }
          },
        },
        { divider: true },
        {
          value: "editMasterCgs",
          name: getTranslation("view:edit.masterCGs"),
          action: () => {
            if (
              !json.shipData.lcgOptions.lpp &&
              !(
                json.shipData.lcgOptions?.originalDataSource?.reference ===
                LcgReferenceEnum.AFT_PERPENDICULAR
              )
            ) {
              this.dialogError.show(
                getTranslation("view:edit.masterCGs"),
                getTranslation("view:edit.noLppDefinedForCGs"),
              );
              return;
            }

            const editForm = createCGsMasterEdit(
              json.shipData,
              json.sizeSummary,
              this.editDrawer,
            );

            const drawer = this.editDrawer.getEditDrawer({
              title: getTranslation("view:edit.masterCGs"),
              onSave: editForm.submitFunction,
              readOnlyMode: this.readonlyMode,
            });

            drawer.appendChild(editForm.node);
            drawer.show();
          },
        },
        {
          value: "editBayLevelCgs",
          name: getTranslation("view:edit.bayLevelCGs"),
          action: () => {
            if (ovdJsonStore.totalSlotsDefined === 0) {
              this.dialogError.show(
                getTranslation("view:edit.bayLevelCGs"),
                getTranslation("view:edit.noSlotsDefinedForCGs"),
              );
              return;
            } else if (
              !json.shipData.lcgOptions.lpp &&
              !(
                json.shipData.lcgOptions?.originalDataSource?.reference ===
                LcgReferenceEnum.AFT_PERPENDICULAR
              )
            ) {
              this.dialogError.show(
                getTranslation("view:edit.bayLevelCGs"),
                getTranslation("view:edit.noLppDefinedForCGs"),
              );
              return;
            }

            const editForm = createCGsEdit(
              json.shipData,
              json.sizeSummary,
              json.baysData,
              this.editDrawer,
            );

            const drawer = this.editDrawer.getEditDrawer({
              title: getTranslation("view:edit.bayLevelCGs"),
              onSave: editForm.submitFunction,
              readOnlyMode: this.readonlyMode,
            });

            drawer.appendChild(editForm.node);
            drawer.show();
          },
        },
        { divider: true },
        {
          value: "viewMissingCGs",
          name: getTranslation("view:edit.cgHelpers.viewMissingCGs.title"),
          action: () => {
            if (calculateMissingCGs(json).totalMissingCGs === 0) {
              this.dialogError.show(
                getTranslation("view:edit.cgHelpers.viewMissingCGs.title"),
                getTranslation(
                  "view:edit.cgHelpers.viewMissingCGs.noMissingMinimumCGs",
                ),
              );
              return;
            }

            const editForm = createMissingCGsDialog(json);

            const drawer = this.editDrawer.getEditDrawer({
              title: getTranslation("view:edit.cgHelpers.viewMissingCGs.title"),
              onSave: () => true,
              readOnlyMode: this.readonlyMode,
            });

            drawer.appendChild(editForm);
            drawer.show();
          },
        },
        { divider: true },
        {
          value: "removeCGs",
          name: getTranslation("view:importStaf.removeCGs"),
          action: () => {
            const editForm = createRemoveCGsDialog(json);

            const drawer = this.editDrawer.getEditDrawer({
              title: getTranslation("view:importStaf.removeCGs"),
              onSave: editForm.submitFunction,
              readOnlyMode: this.readonlyMode,
            });

            drawer.appendChild(editForm.node);
            drawer.show();
          },
        },
      ],
    });

    const parts = [
      {
        name: "shipData",
        title: "view:shipDataTitle",
        data: shipPartDataElements,
        editAction: createEditButton({
          id: "editGeneral",
          pill: true,
          title: getTranslation("view:edit.shipData"),
          disabled: !this.canOpenDrawer,
          onClick: () => {
            const editForm = createGeneralEdit(
              json.shipData,
              json.baysData,
              this.editDrawer.submitButton,
            );

            if (editForm) {
              const drawer = this.editDrawer.getEditDrawer({
                title: getTranslation("view:edit.shipData"),
                onSave: editForm.submitFunction,
                readOnlyMode: this.readonlyMode,
              });
              drawer.appendChild(editForm.node);
              drawer.show();
            }
          },
        }),
      },
      {
        name: "sizeSummary",
        title: "view:sizeSummaryTitle",
        data: sizeSummaryDataElements,
        editAction: createEditButton({
          id: "editSize",
          pill: true,
          title: getTranslation("view:edit.size"),
          disabled: !this.canOpenDrawer,
          onClick: () => {
            const editForm = createSizeEdit(
              json.sizeSummary,
              json.baysData,
              this.editDrawer.submitButton,
            );

            if (editForm) {
              const drawer = this.editDrawer.getEditDrawer({
                title: getTranslation("view:edit.size"),
                onSave: editForm.submitFunction,
                readOnlyMode: this.readonlyMode,
              });
              drawer.appendChild(editForm.node);
              drawer.show();
            }
          },
        }),
      },
      {
        name: "cgsSummary",
        title: "view:cgsDataTitle",
        data: cgsPartDataElements,
        disabled: !this.canOpenDrawer,
        editAction: editCGsButton,
      },
    ];

    const wrapper = document.createElement("div");

    const titleWithActions = this.setHeaderInfo({
      viewType,
      version,
      lcgVcgTcgAndPairings,
    });

    this.titleWithActions = titleWithActions;
    wrapper.appendChild(titleWithActions);

    // Initial parts (from Config)
    parts.forEach((part) => {
      wrapper.appendChild(
        createPart(part.name, part.title, part.data, part.editAction),
      );
    });

    // Side View
    wrapper.appendChild(
      createSideView({
        sizeSummary: json.sizeSummary,
        lcgVcgTcgAndPairings,
        editActionNode: createEditButton({
          id: "editBulkheads",
          pill: true,
          disabled: !this.canOpenDrawer,
          title: getTranslation("view:edit.bulkheads.title"),
          onClick: () => {
            const editForm = createBulkheadsEdit(
              json.sizeSummary,
              json.baysData,
              this.editDrawer,
            );

            const drawer = this.editDrawer.getEditDrawer({
              title: getTranslation("view:edit.bulkheads.title"),
              onSave: editForm.submitFunction,
              readOnlyMode: this.readonlyMode,
            });

            this.editDrawer.fixedScrollOverflow = true;
            drawer.appendChild(editForm.node);
            drawer.show();
          },
        }),
        bayNumbersDoubleClickAction: (node: SVGElement) => {
          const bay = node.dataset.bay;
          const el = document.getElementById(`bay-box-component-${bay}`);
          if (el) el.scrollIntoView({ block: "nearest", behavior: "auto" });
        },
      }),
    );

    // Deck View
    wrapper.appendChild(
      createDeckView({
        sizeSummary: json.sizeSummary,
        lidData: json.lidData,
        lcgVcgTcgAndPairings,
        editActionNode: createEditButton({
          id: "editHatchCovers",
          pill: true,
          disabled: !this.canOpenDrawer,
          title: getTranslation("view:edit.hatchCovers"),
          onClick: () => {
            const editForm = createLidsEdit(
              json.sizeSummary,
              json.baysData,
              validateLidData(json.lidData),
              lcgVcgTcgAndPairings,
              this.keepDrawerOpened,
              this.editDrawer,
            );

            const drawer = this.editDrawer.getEditDrawer({
              title: getTranslation("view:edit.hatchCovers"),
              onSave: editForm.submitFunction,
              readOnlyMode: this.readonlyMode,
            });

            this.editDrawer.fixedScrollOverflow = true;
            drawer.appendChild(editForm.node);
            drawer.show();
          },
        }),
        bayNumbersDoubleClickAction: (node: SVGElement) => {
          const bay = node.dataset.bay;
          const el = document.getElementById(`bay-box-component-${bay}`);
          if (el) el.scrollIntoView({ block: "nearest", behavior: "auto" });
        },
      }),
    );

    // Bays
    wrapper.appendChild(
      createBaySection(
        json.baysData,
        json.sizeSummary,
        json.shipData,
        json.lidData,
        this.bayDrawOptions,
        this.showData.bind(this),
        this.editDrawer,
        this.dialogError,
        this.readonlyMode,
        this.canOpenDrawer,
      ),
    );

    removeChildren(this.mainNode);
    this.mainNode.appendChild(wrapper);

    if (!json.shipData.imoCode) {
      this.requestImoCode();
    }

    // DEBUGGING PURPOSES ONLY
    if (process.env.NX_PUBLIC_STAGE === "dev") window.xson = json;
  };

  showError = (e: ICustomError, errorStr = "errors:notValidStafFile") => {
    this.dialogError.show(
      getTranslation("errors:errorHasOcurred"),
      getTranslation(errorStr),
    );
    console.error(e);
  };

  changeFileState = async (s: FileStateEnum) => {
    if (!ovdJsonStore.currentJson?.shipData.imoCode) {
      this.dialogError.show(
        getTranslation("view:imoCodeNeeded"),
        getTranslation("view:imoCodeNeededHelpText"),
      );
      return;
    }

    const inputChangesMade = document.createElement("sl-textarea");
    inputChangesMade.label = getTranslation("view:explainModifications");
    inputChangesMade.rows = 3;

    const changeOkButton = document.createElement("sl-button");
    changeOkButton.slot = "footer";
    changeOkButton.innerHTML = getTranslation(`enums:FileStateActionEnum.${s}`);
    changeOkButton.variant = "primary";
    changeOkButton.addEventListener("click", async () => {
      (this.confirmChangeStateDialog as SlDialog).hide();
      const res = await ovdJsonStore.setFileState(s, inputChangesMade.value);
      if (res?.statusCode !== 200) {
        this.dialogError.show(
          getTranslation(`errors:errorHasOcurred`),
          res?.message
            ? getTranslation(res.message)
            : getTranslation(`errors:fileStateChangeError`),
        );
      }
    });

    if (!this.confirmChangeStateDialog) {
      this.confirmChangeStateDialog = document.createElement("sl-dialog");
      this.appendChild(this.confirmChangeStateDialog);
    } else {
      removeChildren(this.confirmChangeStateDialog);
    }

    this.confirmChangeStateDialog.label = getTranslation(
      s === FileStateEnum.DRAFT
        ? "view:fileStateChange.title"
        : "view:fileStateChange.titleForPublication",
    );

    this.confirmChangeStateDialog.appendChild(changeOkButton);

    if (s !== FileStateEnum.DRAFT) {
      this.confirmChangeStateDialog.appendChild(inputChangesMade);
    }

    this.confirmChangeStateDialog.show();
  };

  notifyStateChanged = async (ev: {
    oldState: IFileState;
    newState: IFileState;
  }) => {
    const json = ovdJsonStore.currentJson;
    if (this.notifiedStateChanged || !json) return;

    const viewType = getViewTypesFromRouteKey(
      router.currentRouteKey,
    ) as ViewJsonTypeEnum;

    const version =
      (viewType === ViewJsonTypeEnum.VIEW_VERSION &&
        router.getRouteParams().version) ||
      undefined;

    if (this.titleWithActions) {
      const node = this.titleWithActions;
      const newNode = this.setHeaderInfo({
        viewType,
        version,
        lcgVcgTcgAndPairings: getBayLcgVcgTcgAndPairings(
          json.baysData,
          json.shipData.masterCGs,
        ),
      });
      node.replaceWith(newNode);
    }

    if (ev.newState === ev.oldState || ev.oldState === "DRAFT") return;

    this.notifiedStateChanged = true;

    window.clearTimeout(this.intNotifiedStateChanged);
    this.intNotifiedStateChanged = window.setTimeout(() => {
      this.dialogError.show(
        getTranslation("general:common.attention"),
        getTranslation("view:notificationStateModified"),
      );
    }, 500);
  };

  private keepDrawerOpened = (isOpened: boolean) => {
    this.editDrawer.shouldNotClose = isOpened;
  };

  private requestImoCode = async () => {
    globalStore.touchLastUserInteraction();

    const json = ovdJsonStore.currentJson;
    if (!json) return;

    const dialog = document.createElement("sl-dialog");
    dialog.label = getTranslation("view:imoCodeNeeded");
    dialog.setAttribute("style", "--width: 400px");

    const imoCodeInput = document.createElement("sl-input");
    imoCodeInput.label = getTranslation("view:imoCode");
    imoCodeInput.id = "imoCode";

    const okButton = document.createElement("sl-button");
    okButton.slot = "footer";
    okButton.innerHTML = getTranslation("general:common.ok");
    okButton.variant = "primary";

    okButton.addEventListener("click", async () => {
      const imoCode = imoCodeInput.value;
      if (!imoCode) return;

      okButton.disabled = true;
      okButton.loading = true;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let imoApiState: ImoFoundResultEnum = ImoFoundResultEnum.INITIALIZED;

      // Call Marine Traffic API ********************************************
      const nameInfo = await Services.marineTrafficApi.searchVesselByImoCode(
        imoCode,
      );

      if (nameInfo?.data?.source === "error") {
        if (imoCode) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          imoApiState = ImoFoundResultEnum.NOT_FOUND_CONTINUE;
          ovdJsonStore.setShipDataGeneralInfo({
            ...json.shipData,
            featuresAllowed: [],
            imoCode,
          });
        }
        dialog.hide();
      }

      const match = nameInfo?.data?.match;

      if (!match) {
        createNotFoundImoDialog({
          parentNode: this,
          imoCode,
          onButtonClicked: (btnSelected): void => {
            switch (btnSelected) {
              case "continue":
                imoApiState = ImoFoundResultEnum.NOT_FOUND_CONTINUE;
                dialog.hide();
                break;
              case "fix":
                imoApiState = ImoFoundResultEnum.NOT_FOUND_FIX;
                imoCodeInput.focus();
                okButton.disabled = false;
                okButton.loading = false;
                break;
            }
          },
        });
      } else {
        createFoundImoDialog({
          parentNode: this,
          imoCode,
          lastFetched: String(match.modifiedAt),
          btnSeeTvlSearchResults: undefined,
          shipName: match.SHIPNAME,
          onButtonClicked: (btnSelected): void => {
            switch (btnSelected) {
              case "continue":
                imoApiState = ImoFoundResultEnum.FOUND_CONTINUE;
                dialog.hide();

                {
                  const lpp = Number(
                      match.particularsJson?.LENGTH_B_W_PERPENDICULARS || 0,
                    ),
                    loa = Number(match.particularsJson?.LENGTH_OVERALL || 0);

                  ovdJsonStore
                    .setShipDataGeneralInfo({
                      ...json.shipData,
                      featuresAllowed: [],
                      imoCode,
                      callSign: match.CALLSIGN || "",
                      shipName: match.SHIPNAME || "",
                      yearBuilt: Number(match.YEAR_BUILT || 0),
                      lineOperator: match.particularsJson?.MANAGER || "",
                    })
                    .setCgOptions(
                      ValuesSourceEnum.ESTIMATED,
                      ValuesSourceEnum.ESTIMATED,
                      ValuesSourceEnum.ESTIMATED,
                      loa,
                      0,
                      lpp,
                    );
                }

                break;
              case "fix":
                imoApiState = ImoFoundResultEnum.FOUND_FIX_IMO;
                imoCodeInput.focus();
                okButton.disabled = false;
                okButton.loading = false;
                break;
              case "doubleCheck":
                imoApiState = ImoFoundResultEnum.FOUND_MANUAL_SET;

                Services.marineTrafficApi
                  .searchVesselByImoCode(imoCode || "", true)
                  .then((doucleCheckNameInfo) => {
                    const newM = doucleCheckNameInfo?.data?.match;
                    if (newM) {
                      {
                        const lpp = Number(
                            newM.particularsJson?.LENGTH_B_W_PERPENDICULARS ||
                              0,
                          ),
                          loa = Number(
                            newM.particularsJson?.LENGTH_OVERALL || 0,
                          );

                        ovdJsonStore
                          .setShipDataGeneralInfo({
                            ...json.shipData,
                            featuresAllowed: [],
                            imoCode,
                            callSign: newM.CALLSIGN || "",
                            shipName: newM.SHIPNAME || "",
                            yearBuilt: Number(newM.YEAR_BUILT || 0),
                            lineOperator: newM.particularsJson?.MANAGER || "",
                          })
                          .setCgOptions(
                            ValuesSourceEnum.ESTIMATED,
                            ValuesSourceEnum.ESTIMATED,
                            ValuesSourceEnum.ESTIMATED,
                            loa,
                            0,
                            lpp,
                          );
                      }
                    }
                    dialog.hide();
                  });
                break;
            }
          },
        });
      }
    });

    dialog.addEventListener("sl-request-close", (event) => {
      const source = (event as CustomEvent).detail.source;
      if (source === "overlay" || source === "close-button") {
        event.preventDefault();
      }
    });

    dialog.addEventListener("sl-after-hide", () => {
      console.log("sl-after-hide");
    });

    dialog.appendChild(okButton);
    dialog.appendChild(imoCodeInput);

    this.appendChild(dialog);
    dialog.show();

    setTimeout(() => {
      imoCodeInput.focus();
    }, 500);
  };

  private setHeaderInfo({
    viewType,
    version,
    lcgVcgTcgAndPairings,
  }: {
    viewType: ViewJsonTypeEnum;
    version: string | undefined;
    lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  }) {
    const fileName = String(ovdJsonStore.filenameSanitized);
    const currentFileState = ovdJsonStore.tvdId?.fileState || "DRAFT";

    const titleWithActions = document.createElement(
      "title-with-actions-component",
    );

    titleWithActions.titleNodeName = "h1";

    if (viewType === ViewJsonTypeEnum.VIEW_VERSION && version) {
      const fragment = document.createElement("span");
      const text = document.createTextNode(
        (ovdJsonStore.currentJson?.shipData.shipName || fileName) + "  ",
      );

      fragment.appendChild(text);
      titleWithActions.setHeadingNode(fragment);

      const fileId = ovdJsonStore.tvdId?.fileId || "";

      if (fileId)
        Services.files
          .getFileVersions(
            ovdJsonStore.tvdId?.organizationId ||
              securityModule.currentOrganizationId,
            fileId,
          )
          .then((versionsResponse) => {
            const select = createVersionsDropdown({
              selectedVersion: version,
              addCurrentVersion: true,
              versionsResponse,
              onChange: async (v, prev) => {
                if (v === prev) return;

                if (v) {
                  router.navigate(
                    routeFns.ovdViewOnlyVersion(fileId, encodeURI(v)),
                    {
                      organizationId: ovdJsonStore.tvdId?.organizationId,
                      cloudId: fileId,
                      source: "cloudOvdJson",
                    },
                  );
                } else {
                  router.navigate(routeFns.ovdEdit(fileId));
                }
              },
            });
            if (select) fragment.appendChild(select);
          });
    } else {
      titleWithActions.titleHtml =
        ovdJsonStore.currentJson?.shipData.shipName || fileName;
    }

    const actionsNode = document.createElement("div");
    actionsNode.slot = "actions";
    actionsNode.className = "slot-actions";

    // Loading Indicator
    actionsNode.appendChild(this.loadingIndicator);
    this.loadingIndicator.style.setProperty("--size", "0.5rem");

    // Read Only Badge
    if (this.readonlyMode) {
      const readonlyBadge = document.createElement("sl-badge");
      readonlyBadge.variant = "danger";
      readonlyBadge.pulse = true;
      readonlyBadge.innerHTML = getTranslation("general:common.readOnly");

      actionsNode.appendChild(readonlyBadge);
    }

    // File tags
    if (
      viewType === ViewJsonTypeEnum.EDIT &&
      securityModule.currentOrganizationId ===
        ovdJsonStore.tvdId?.organizationId
    ) {
      const btnFileTags = document.createElement("sl-button");
      btnFileTags.size = "small";
      btnFileTags.pill = true;
      btnFileTags.variant = "primary";
      btnFileTags.title = getTranslation("general:fileTags.tags");
      btnFileTags.outline = true;
      btnFileTags.disabled = true;
      btnFileTags.addEventListener("click", () => {
        createModalToTagFiles({
          fileId: ovdJsonStore.tvdId?.fileId,
          fileName: ovdJsonStore.currentJson?.shipData.shipName || fileName,
          modal: this.dialogTagFile,
          filesTags: this.fileTags,
          onUpdated: (fileTags) => {
            if (fileTags) this.fileTags = fileTags;
            btnFileTags.outline = false;
            window.setTimeout(() => {
              btnFileTags.outline = true;
            }, 250);
          },
        });
      });

      const icon = document.createElement("sl-icon");
      icon.name = "tags";
      icon.slot = "prefix";
      btnFileTags.appendChild(icon);

      const tooltip = document.createElement("sl-tooltip");
      tooltip.content = getTranslation("general:fileTags.tags");
      tooltip.appendChild(btnFileTags);

      actionsNode.appendChild(tooltip);

      Services.organizations.getFileTags().then((ft) => {
        if (ft.data) this.fileTags = ft.data;
        btnFileTags.disabled = false;
      });
    }

    // File NOTES
    if (
      viewType === ViewJsonTypeEnum.EDIT &&
      securityModule.currentOrganizationId ===
        ovdJsonStore.tvdId?.organizationId
    ) {
      const btnFileNotes = document.createElement("sl-button");
      btnFileNotes.size = "small";
      btnFileNotes.pill = true;
      btnFileNotes.variant = "primary";
      btnFileNotes.title = getTranslation("general:fileNotes.fileNotes");
      btnFileNotes.outline = true;

      btnFileNotes.addEventListener("click", () => {
        createModalToForNotes({
          organizationId: ovdJsonStore.tvdId?.organizationId,
          fileId: ovdJsonStore.tvdId?.fileId,
          fileName: ovdJsonStore.currentJson?.shipData.shipName || fileName,
          modal: this.dialogFileNotes,
          organizationUsersBySub: this.organizationUsersBySub,
          onUpdated: () => {
            btnFileNotes.outline = false;
            window.setTimeout(() => {
              btnFileNotes.outline = true;
            }, 250);
          },
        });
      });

      const icon = document.createElement("sl-icon");
      icon.name = "stickies";
      icon.slot = "prefix";
      btnFileNotes.appendChild(icon);

      const tooltip = document.createElement("sl-tooltip");
      tooltip.content = getTranslation("general:fileNotes.fileNotes");
      tooltip.appendChild(btnFileNotes);

      actionsNode.appendChild(tooltip);
    }

    // File History button. Only for same OrgId or Tedivo Admin. Double checked on Server Side
    if (
      viewType === ViewJsonTypeEnum.EDIT &&
      (securityModule.currentOrganizationId ===
        ovdJsonStore.tvdId?.organizationId ||
        securityModule.userIsTedivoAdmin)
    ) {
      const historyAndVersionsBtn = createFileHistoryAndVersionsButton(
        this.dialogHistory,
        false,
      );

      const btnTvlMap = document.createElement("sl-button");
      //btnTvlMap.innerHTML = getTranslation("view:fileMap.tvlMap");
      btnTvlMap.size = "small";
      btnTvlMap.pill = true;
      btnTvlMap.variant = "primary";
      btnTvlMap.title = getTranslation("view:fileMap.tvlMap");
      btnTvlMap.outline = true;
      btnTvlMap.addEventListener("click", () => {
        const fileId = ovdJsonStore.tvdId?.fileId || "";
        const fileOrgId = ovdJsonStore.tvdId?.organizationId || "";
        router.navigate(routeFns.fileMap(fileId), {
          organizationId: fileOrgId,
        });
      });

      const icon = document.createElement("sl-icon");
      icon.name = "geo";
      icon.slot = "prefix";
      btnTvlMap.appendChild(icon);

      const tooltip = document.createElement("sl-tooltip");
      tooltip.content = getTranslation("view:fileMap.tvlMap");
      tooltip.appendChild(btnTvlMap);

      actionsNode.appendChild(tooltip);

      if (historyAndVersionsBtn) actionsNode.appendChild(historyAndVersionsBtn);
    }

    // Download JSON button
    const ovdJsonDownloadBtn = createDownloadOVDJsonLink(
      viewType,
      ovdJsonStore.tvdId?.organizationId || "",
      ovdJsonStore.tvdId?.fileId || "",
      currentFileState,
      lcgVcgTcgAndPairings.cgsStats,
    );

    if (ovdJsonDownloadBtn) actionsNode.appendChild(ovdJsonDownloadBtn);

    // Vote Button (only in Read Only Mode and different Organization ID)
    if (
      viewType === ViewJsonTypeEnum.VIEW_OVD &&
      this.readonlyMode &&
      securityModule.currentOrganizationId !==
        ovdJsonStore.tvdId?.organizationId
    ) {
      const btnUpVote = document.createElement("tvd-vote-tvl-component");
      btnUpVote.fileId = ovdJsonStore.tvdId?.fileId || "";
      btnUpVote.fileOrgId = ovdJsonStore.tvdId?.organizationId || "";
      actionsNode.appendChild(btnUpVote);
    }

    // File State Button. Only for same OrgId or Tedivo Admin
    if (
      viewType === ViewJsonTypeEnum.EDIT &&
      (securityModule.currentOrganizationId ===
        ovdJsonStore.tvdId?.organizationId ||
        securityModule.userIsTedivoAdmin)
    ) {
      const btnFileStatePill = createFileStateButton(
        FileStateEnum[currentFileState],
        calculateFileStatePermissions(),
        this.changeFileState,
        this.readonlyMode,
      );

      actionsNode.appendChild(btnFileStatePill);
    }

    if (
      viewType === ViewJsonTypeEnum.VIEW_VERSION &&
      (securityModule.currentOrganizationId ===
        ovdJsonStore.tvdId?.organizationId ||
        securityModule.userIsTedivoAdmin)
    ) {
      const icon = document.createElement("sl-icon");
      icon.name = "code-slash";
      icon.slot = "prefix";

      const btnCompare = document.createElement("sl-button");
      btnCompare.innerHTML = getTranslation("view:comparer.compareOvd");
      btnCompare.size = "small";
      btnCompare.pill = true;
      btnCompare.variant = "primary";
      btnCompare.title = getTranslation("view:comparer.compareOvd");
      btnCompare.outline = true;
      btnCompare.appendChild(icon);

      btnCompare.addEventListener("click", () => {
        const fileId = ovdJsonStore.tvdId?.fileId || "";
        const fileOrgId = ovdJsonStore.tvdId?.organizationId || "";
        const version = router.getRouteParams().version;
        if (!version) return;

        const route = routeFns.fileCompareOwnVersion(fileId, version);
        router.navigate(route, { organizationId: fileOrgId });
      });

      actionsNode.appendChild(btnCompare);
    }

    titleWithActions.appendChild(actionsNode);
    return titleWithActions;
  }
}

customElements.define("tvd-view-json-component", TVDViewJsonComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-view-json-component": TVDViewJsonComponent;
  }
}

function setLcgRefViewOptionsToAftPerpFwd(): void {
  globalUnits.setLcgRef(UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD);
  document.body.dispatchEvent(new CustomEvent("globalUnitsChanged"));
}

function getPreferences(): [SizeSmallMidLargeEnum, CellSpacingEnum] {
  const baySizeOption = getPreferencesValue(
    "bay-data-size",
  ) as SizeSmallMidLargeEnum;

  const bayCellSeparationOption = getPreferencesValue(
    "bay-cell-separation",
  ) as CellSpacingEnum;

  return [
    baySizeOption ?? SizeSmallMidLargeEnum.SMALL,
    bayCellSeparationOption ?? CellSpacingEnum.NO_SPACE,
  ];
}

async function getCloudOvdJson(
  id: string,
  orgId: string | undefined,
  version: string | undefined,
): Promise<{
  name: string;
  json: IOpenVesselDefinitionV1;
  userSub: string;
  organizationId: string;
  fileState: IFileState;
  createdAt: Date | undefined;
  lastModified: Date | undefined;
  lastModifiedBy: string | undefined;
  fromBvoName: string | undefined;
  lastComment: string | undefined;
} | null> {
  if (version) {
    const { statusCode, data } =
      version && orgId
        ? await Services.files.getFileVersion(orgId, id, version)
        : { statusCode: 404, data: undefined };

    if (statusCode !== 200 || data === undefined) {
      if (statusCode === 402) {
        throw new Error("errors:planExpired");
      } else {
        throw new Error("errors:fileNotFound");
      }
    }

    return {
      json: data.data as unknown as IOpenVesselDefinitionV1,
      name: data.details.name,
      userSub: "",
      organizationId: data.organizationId,
      fileState: data.details.state,
      createdAt: new Date(data.date),
      lastModified: new Date(data.date),
      lastModifiedBy: data.details.lastAuthor,
      fromBvoName: "",
      lastComment: data.details.comments,
    };
  } else {
    const { statusCode, data, message } = await (orgId
      ? Services.files.getAdmin(id, orgId)
      : Services.files.get(id));

    if (statusCode !== 200 || data === undefined) throw new Error(message);

    const json = data.data as unknown as IOpenVesselDefinitionV1;
    const name = json.shipData?.shipName ?? "";

    return {
      json,
      name,
      userSub: data.userSub,
      organizationId: data.organizationId,
      fileState: data.state,
      createdAt: data.createdAt ? new Date(data.createdAt) : undefined,
      lastModified: new Date(data.lastModified),
      lastModifiedBy: data.lastAuthor,
      fromBvoName: data.fromBvoName,
      lastComment: data.lastComment,
    };
  }
}

function createFileStateButton(
  currentState: FileStateEnum,
  publishRights: {
    canChangeStateDraft: boolean;
    canChangeStatePublished: boolean;
    canChangeStateCommunity: boolean;
  },
  cb: (s: FileStateEnum) => Promise<void>,
  readonlyMode: boolean,
): SelectShoelace<FileStateEnum> | HTMLDivElement {
  const availableOptions: ITedivoShoelaceSelectOptions[] = [];

  if (!readonlyMode) {
    if (
      currentState !== FileStateEnum.DRAFT &&
      publishRights.canChangeStateDraft
    )
      availableOptions.push({
        name: getTranslation(
          `view:fileStateChange.from.${currentState}.${FileStateEnum.DRAFT}`,
        ),
        value: FileStateEnum.DRAFT,
      });

    if (
      currentState !== FileStateEnum.ORG_READY &&
      publishRights.canChangeStatePublished
    )
      availableOptions.push({
        name: getTranslation(
          `view:fileStateChange.from.${currentState}.${FileStateEnum.ORG_READY}`,
        ),
        value: FileStateEnum.ORG_READY,
      });

    if (
      currentState !== FileStateEnum.COMMUNITY &&
      publishRights.canChangeStateCommunity
    )
      availableOptions.push({
        name: getTranslation(
          `view:fileStateChange.from.${currentState}.${FileStateEnum.COMMUNITY}`,
        ),
        value: FileStateEnum.COMMUNITY,
      });
  }

  const changeStateSelect = createSelectShoelace({
    id: "fileStateBtn",
    caret: false,
    pill: true,
    ommitCheckSign: true,
    size: "small",
    variant: "neutral",
    disabled: availableOptions.length === 0,
    selectedValue: currentState,
    buttonText: getTranslation(`enums:FileStateEnum.${currentState}`),
    title: getTranslation("general:fileState"),
    options: availableOptions,
    onChange: async (v: FileStateEnum) => {
      cb(v);
    },
    onNodesCreated: (_, button) => {
      const icon = document.createElement("sl-icon");
      icon.name = "info-circle";
      icon.slot = "prefix";
      button.appendChild(icon);
    },
  });

  return changeStateSelect;
}

function createFileHistoryAndVersionsButton(
  dialogHistory: SlDialog,
  onlyIcon = false,
): HTMLElement | undefined {
  const tvdId = ovdJsonStore.tvdId;

  if (!tvdId) return undefined;

  const icon = document.createElement("sl-icon");
  icon.name = "clock-history";
  icon.slot = "prefix";

  const fileHistoryBtn = document.createElement("sl-button");
  fileHistoryBtn.pill = true;
  fileHistoryBtn.variant = "primary";
  fileHistoryBtn.outline = true;
  fileHistoryBtn.size = "small";

  fileHistoryBtn.addEventListener("click", () => {
    removeChildren(dialogHistory);
    const node = showFileHistory(tvdId);
    if (node) dialogHistory.appendChild(node);
    dialogHistory.show();
  });

  if (!onlyIcon) {
    fileHistoryBtn.innerHTML = getTranslation("view:edit.fileHistory.title");
    fileHistoryBtn.appendChild(icon);
    return fileHistoryBtn;
  } else {
    const tooltip = document.createElement("sl-tooltip");
    tooltip.content = getTranslation("view:edit.fileHistory.title");
    tooltip.appendChild(fileHistoryBtn);
    fileHistoryBtn.appendChild(icon);
    return tooltip;
  }
}

function calculateFileStatePermissions() {
  return {
    canChangeStateDraft: securityModule.userHasPermission(
      systemRights.USER.ChangeFileStatusToDraft,
    ),
    canChangeStatePublished:
      securityModule.userHasPermission(
        systemRights.ORG.PublishOrganizationFile,
      ) &&
      securityModule.userHasPermission(
        systemRights.USER.ChangeFileStatusToOrganization,
      ),
    canChangeStateCommunity:
      securityModule.userHasPermission(systemRights.ORG.PublishCommunityFile) &&
      securityModule.userHasPermission(
        systemRights.USER.ChangeFileStatusToCommunity,
      ),
  };
}
